.table-padding > tbody > tr > td {
    padding: 1px;
}

.btn-sm-padding-right-left {
    padding:0.25rem 0.0rem;
}

.dekra-font-size12, .dekra-font-size12 a:hover, .dekra-font-size12 a:visited, .dekra-font-size12 a:active {
    font-size: 12px;
    color: rgb(85, 85, 85) !important;
}

.error-text {
    color: red;
}

.fadeInFromLeftClass {
    animation-duration: 1s;
    animation-timing-function: ease-in;
}

@keyframes fadeInFromLeft {
    from {
        transform: translateX(-200px);
        opacity: 0%;
    }
    to {
        transform: translateX(0px);
        opacity: 100%;
    }
}

.card-body-dekra {
    padding: 0px;
}

.card-title-dekra {
    padding: 5px;
    color: #fff;
    background: linear-gradient(#45A358, #007D40);
    margin-bottom: 0px;
}
.card-text-dekra{
    padding: 15px;
}
.card-header-dekra{
    padding-bottom:5px;
}
.btn-link-color-dekra {
    color: #ffff;
}
.btn-link-color-dekra:hover {
    color: #e9ecef;
}
.text-uppercase {
    text-transform: uppercase !important;
}

.headline-m30 {
    margin-top: 30px;
}

.headline-m20 {
    color: #007D40;
    /*color: #555555;*/
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
}

.dekra-no-margin {
    padding: 0 0 0 0;
}

.dekra-no-padding {
    padding-left: 0;
    padding-right: 0;
}

.dekra-green-arrow-ul li {
    list-style-image: url('images/list-arrow-green.png');
}

.dekra-res-padding-right-md {
    padding: 0 0 0 15px;
}

@media screen and (max-width: 992px) {
    .dekra-res-padding-right-md {
        padding: 0 0 0 0px;
    }
}

.dekra-pull-right-md {
    float: right !important;
}

@media screen and (max-width: 768px) {
    .dekra-pull-right-md {
        float: right !important;
    }
}

@media screen and (max-width: 1200px) {
    .dekra-content-full-xs-medium-sm {
        max-width: 630px;
        min-width: 630px;
        width: 100%;
    }
}

@media screen and (max-width: 668px) {
    .dekra-content-full-xs-medium-sm {
        width: 100%;
        max-width: 380px;
        min-width: 380px;
    }
}

@media screen and (max-width: 321px) {
    .dekra-content-full-xs-medium-sm {
        width: 100%;
        max-width: 500px;
        min-width: 280px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 1025px) {
    .content-full-xs {
        max-width: 500px;
        min-width: 500px;
        width: 100%;
    }
}

@media screen and (max-width: 1025px) {
    .content-full-sm {
        min-width: 500px;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .content-full-xs {
        width: 100%;
        max-width: 500px;
        min-width: 300px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1490px) {
    .box .dekra-renew-content-full-xs {
        width: 100%;
        min-width: 100px;
    }
}

@media screen and (max-width: 675px) {
    .dekra-renew-content-full-xs {
        width: 100%;
        min-width: 100px;
    }
}

@media screen and (max-width: 1400px) {
    .dekra-renew-content2-full-xs {
        width: 100%;
        min-width: 100px;
    }
}
/*index.html*/
.dekra-line-break {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: 0;
    height: 1px;
    background: #D1D1D1;
}

.dekra-wrapper {
    /* border-bottom: 1px solid #D1D1D1; */
    border-left: 10px solid #007D40;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

.dekra-content-wrapper {
    padding: 25px 0 10px 25px;
}

@media screen and (max-width: 668px) {
    .dekra-content-wrapper {
        padding: 0 0 0 0;
    }
}
/*END index.html*/



.dekra-btn-file {
    position: relative;
    /*overflow: hidden;*/
}

    .dekra-btn-file input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        /*     min-width: 100%;
    min-height: 100%; */
        max-width: 150px;
        max-height: 20px;
        font-size: 100px;
        /* text-align: right; */
        filter: alpha(opacity=0);
        opacity: 0;
        /*outline: none;*/
        background: white;
        cursor: inherit;
        /*     display: block; */
    }

.dekra-breadcrumb {
    /*centering*/
    display: inline-block;
    /*box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.35);*/
    overflow: hidden;
    border-radius: 5px;
    /*Lets add the numbers for each link using CSS counters. flag is the name of the counter. to be defined using counter-reset in the parent element of the links*/
    counter-reset: flag;
    vertical-align: middle;
}

    .dekra-breadcrumb a {
        text-decoration: none;
        outline: none;
        display: block;
        float: left;
        font-size: 11px;
        line-height: 36px;
        color: white;
        /*need more margin on the left of links to accomodate the numbers*/
        padding: 0 7.5px 0 55px;
        background: green;
        background: linear-gradient(#F1F1F1, #D1D1D1);
        color: #555;
        position: relative;
    }
        /*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
        .dekra-breadcrumb a:first-child {
            padding-left: 41px;
            border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
        }

            .dekra-breadcrumb a:first-child:before {
                left: 14px;
            }

        .dekra-breadcrumb a:last-child {
            padding-right: 20px;
            border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
        }

        /*hover/active styles*/
        .dekra-breadcrumb a.active /*, .dekra-breadcrumb a:hover*/ {
            background: linear-gradient(#45A358, #007D40);
            color: white;
        }

.dekra-breadcrumb a.active:after /*, .dekra-breadcrumb a:hover:after*/ {
    background: linear-gradient(135deg,#45A358, #007D40);
    color: white;
}


/*adding the arrows for the breadcrumbs using rotated pseudo elements*/
.dekra-breadcrumb a:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px; /*half of square's length*/
    /*same dimension as the line-height of .breadcrumb a */
    width: 36px;
    height: 36px;
    /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
	length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
	if diagonal required = 1; length = 1/1.414 = 0.707*/
    transform: scale(0.707) rotate(45deg);
    /*we need to prevent the arrows from getting buried under the next link*/
    z-index: 1;
    /*background same as links but the gradient will be rotated to compensate with the transform applied*/
    background: #666;
    background: linear-gradient(135deg,#F1F1F1, #D1D1D1);
    /*stylish arrow design using box shadow*/
    box-shadow: 1px -1px 0 1px rgba(0, 0, 0, 0.1), 1px -3px 0 1px rgba(255, 255, 255, 0.1);
    /*
		5px - for rounded arrows and 
		50px - to prevent hover glitches on the border created using shadows*/
    border-radius: 0 0px 0 50px;
}

/*we dont need an arrow after the last link*/
.dekra-breadcrumb a:last-child:after {
    content: none;
}
/*we will use the :before element to show numbers*/
.dekra-breadcrumb a:before {
    content: counter(flag);
    counter-increment: flag;
    /*some styles now*/
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 8px 0;
    position: absolute;
    top: 0;
    left: 30px;
    background: #F1F1F1;
    padding-left: 6px;
}

.dekra-breadcrumb a.active:before {
    background: #EE7F00;
}

.shadow {
    -webkit-box-shadow: 10px 10px 5px #888888;
    -moz-box-shadow: 10px 10px 5px #888888;
    box-shadow: 10px 10px 5px #888888;
}

.dekra-certificat-tabs-content {
    padding: 7.5px;
    border-left: 1px solid #333333;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
}

.dekra-certificat-tabs > li.active > a, 
.dekra-certificat-tabs > li.active > a:hover, 
.dekra-certificat-tabs > li.active > a:focus, 
.dekra-certificat-tabs > li > a:hover, 
.dekra-certificat-tabs > li > a:focus {
    border-right: 1px solid #333333;
    border-left: 1px solid #333333;
    border-top: 1px solid #333333;
    border-bottom: 1px solid white;
    /* border-bottom:none; */
    margin-bottom: 1px;
}

.dekra-certificat-tabs > li > a {
    color: #999999;
    border-right: 1px solid #D1D1D1;
    border-left: 1px solid #D1D1D1;
    border-top: 1px solid #D1D1D1;
    border-bottom: none;
    margin-bottom: 0px;
    /* border-bottom:0px solid white; */
}

    .dekra-certificat-tabs > li > a:hover, 
    .dekra-certificat-tabs > li > a:focus {
        border-bottom: 0px solid white;
    }

.dekra-certificat-tabs {
    border-color: none;
    border-bottom: 1px solid #333333;
}

    .dekra-certificat-tabs > li {
        /* -webkit-box-shadow: 9px -7px 13px 0px rgba(209, 209, 209, 0.75);
-moz-box-shadow:    9px -7px 13px 0px rgba(209, 209, 209, 0.75);
box-shadow:         9px -7px 13px 0px rgba(209, 209, 209, 0.75); */
        border-bottom-color: blue;
    }

    .dekra-certificat-tabs, .dekra-certificat-tabs > ul {
        margin-bottom: 0;
    }

.thumb {
    width: 100px;
    height: 100px;
    margin: 5px;
    float: left;
}

.mini-thumb {
    width: 50px;
    height: 50px;
    float: left;
}

.side-bar-display .panel-group {
    margin-bottom: 0px;
}

.side-bar-display table {
    margin: 0px;
    width: 100%;
}

.side-bar-display table {
    border-collapse: collapse;
    border-spacing: 1px;
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.table-dekra tr td:nth-child(3), .table-dekra tr td:nth-child(4) {
    word-break: break-all;
}

.table-dekra table {
    border-collapse: collapse;
    border-spacing: 1px;
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.table-dekra .grey-bg > td:nth-child(2), .table-dekra .grey-bg > td:nth-child(3) {
    background-color: #f1f1f1;
    vertical-align: middle;
}

.table-dekra .grey-bg-two > td:nth-child(1), .table-dekra .grey-bg-two > td:nth-child(2) {
    background-color: #f1f1f1;
    vertical-align: middle;
}

.dekra-table-display .table-dekra .grey-bg-two > td:nth-child(1), .dekra-table-display .table-dekra .grey-bg-two > td:nth-child(2) {
    vertical-align: text-top;
}


.table-dekra thead tr th {
    background-color: #DFF0D8;
    border: 0px solid #ffffff;
    text-align: left;
    border-width: 0px 0px 1px 1px;
    font-size: 12px;
    font-family: Arial;
    font-weight: bold;
    color: #333333;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.side-bar-display .panel-heading {
    /*background: rgba(226,226,226,1);
background: -moz-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(226,226,226,1)), color-stop(50%, rgba(219,219,219,1)), color-stop(51%, rgba(209,209,209,1)), color-stop(100%, rgba(254,254,254,1)));
background: -webkit-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -o-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -ms-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: linear-gradient(to bottom, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0 );*/
    background: linear-gradient(#F1F1F1, #D1D1D1);
}

    .side-bar-display .panel-heading .h4 {
        color: #666666;
        font-weight: normal;
    }

    .side-bar-display .panel-heading .panel-title > a {
        text-decoration: none;
        cursor: inherit;
    }

.side-bar-display .panel-group .panel + .panel {
    margin-top: 0px;
}

.side-bar-display .panel-group .panel + .panel {
    margin-top: 0px;
}

.side-bar-display .panel-group .panel:nth-child(n+3) .panel-body {
    padding: 0 0 0 0;
}

.plupp {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-top: 1.5px;
    padding: 0 0 0 0;
    border: 1px solid #D1D1D1;
    position: relative;
}

.dekra-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 15.5px 7.5px;
    border-color: transparent transparent rgb(128, 0, 128) transparent;
}

.dekra-triangle-width {
    margin-top: 2px;
    border-width: 0 8px 15px 8px;
}



.top-img {
    width: 300px;
    margin: auto;
}

@media (max-width: 1200px) {
    .top-img {
        width: 100%;
        margin: 0 0 0 0;
    }
}

.cert-table > thead > tr > th {
    padding-top: 3px;
    padding-bottom: 0px;
    /*padding-left:auto; 
	padding-right:auto; */
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: linear-gradient(#45A358, #007D40);
    /*background: rgba(69,163,88,1);
background: -moz-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(69,163,88,1)), color-stop(20%, rgba(69,163,88,1)), color-stop(26%, rgba(69,163,88,1)), color-stop(55%, rgba(0,125,65,1)), color-stop(100%, rgba(0,125,65,1)));
background: -webkit-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -o-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -ms-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: linear-gradient(to bottom, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45a358', endColorstr='#007d41', GradientType=0 );*/
    text-align: center;
}

.cert-table table tr:first-child td:first-child {
    -moz-border-radius-topleft: 7px;
    -webkit-border-top-left-radius: 7px;
    border-top-left-radius: 7px;
}

.cert-table table tr:first-child td:last-child {
    -moz-border-radius-topright: 7px;
    -webkit-border-top-right-radius: 7px;
    border-top-right-radius: 7px;
}

.cert-table tr:last-child td:first-child {
    -moz-border-radius-bottomleft: 7px;
    -webkit-border-bottom-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.cert-table tr:hover td {
}

.grey-row-bg {
    background-color: #f0f0f0;
}

.cert-table tr {
    background-color: #ffffff;
}

.cert-tabletd {
    border-width: 0px 1px 1px 0px;
    text-align: center;
    padding: 7px;
    font-size: 10px;
    font-family: Arial;
    font-weight: normal;
    color: #000000;
}

.cert-table tr:last-child td {
    //border-width:0px 1px 0px 0px;
}

.cert-table tr td:last-child {
    //border-width:0px 0px 1px 0px;
}

.cert-table tr:last-child td:last-child {
    //border-width:0px 0px 0px 0px;
}

.cert-table > thead > tr > th {
    /*background:-o-linear-gradient(bottom, #45a358 5%, #007d40 100%);	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #45a358), color-stop(1, #007d40) );
	background:-moz-linear-gradient( center top, #45a358 5%, #007d40 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#45a358", endColorstr="#007d40");	background: -o-linear-gradient(top,#45a358,007d40);

	background-color:#45a358;

	background-color:#00bf5f;*/
    border: 0px solid #ffffff;
    text-align: center;
    border-width: 0px 0px 1px 1px;
    font-size: 13px;
    font-family: Arial;
    font-weight: bold;
    color: #ffffff;
}

    .cert-table > thead > tr > th > a {
        color: #FFFFFF;
        text-decoration: none;
    }

    .cert-table > thead > tr > th:hover {
        background: -o-linear-gradient(bottom, #45a358 5%, #007d40 100%);
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #45a358), color-stop(1, #007d40) );
        background: -moz-linear-gradient( center top, #45a358 5%, #007d40 100% );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#45a358", endColorstr="#007d40");
        background: -o-linear-gradient(top,#45a358,007d40);
        background-color: #45a358;
    }

.cert-table > thead > tr > th {
    border-width: 0px 0px 1px 0px;
}

.cert-table > thead > tr > th {
    border-width: 0px 0px 1px 1px;
}

.cert-table > tbody > tr > td {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
}

/*.cert-table-wps-tab > tbody > tr > td {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 30px;
    text-align: left;
    vertical-align: middle;
}*/


.cert-table {
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-bottom-color: purple;
    -moz-border-radius-bottomleft: 7px;
    -webkit-border-bottom-left-radius: 7px;
    border-bottom-left-radius: 7px;
    -moz-border-radius-bottomright: 7px;
    -webkit-border-bottom-right-radius: 7px;
    border-bottom-right-radius: 7px;
    -moz-border-radius-topright: 7px;
    -webkit-border-top-right-radius: 7px;
    border-top-right-radius: 7px;
    -moz-border-radius-topleft: 7px;
    -webkit-border-top-left-radius: 7px;
    border-top-left-radius: 7px;
}

    .cert-table table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }

    .cert-table tr:last-child td:last-child {
        -moz-border-radius-bottomright: 7px;
        -webkit-border-bottom-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }



.cert-panel-body {
    padding: 0px;
}

.inline {
    display: inline-block;
}

/*.modal-label-green {
    color: #007D40;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
}*/
/*TODO move to main.css*/
/*.modal-label {
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    color: black;
}*/
/*Animation for certifikat choises, behörighet osv*/
.animate-enter {
    -webkit-animation: enter 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    animation: enter 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    display: block;
    position: relative;
}

@-webkit-keyframes enter {
    from {
        opacity: 0;
        height: 0px;
    }

    20% {
        opacity: .5;
    }

    40% {
        opacity: .15;
        color: red;
    }

    60% {
        opacity: .75;
    }

    80% {
        opacity: .3;
    }

    to {
        opacity: 1;
        height: 30px;
    }
}

@keyframes enter {
    from {
        opacity: 0;
        height: 0px;
    }

    20% {
        opacity: .5;
    }

    40% {
        opacity: .15;
        color: red;
    }

    60% {
        opacity: .75;
    }

    80% {
        opacity: .3;
    }

    to {
        opacity: 1;
        height: 30px;
    }
}

.animate-leave {
    -webkit-animation: leave 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    animation: leave 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    display: block;
    position: relative;
}

@-webkit-keyframes leave {
    to {
        opacity: 0;
        height: 0px;
    }

    80% {
        opacity: .25;
    }

    60% {
        opacity: .10;
        color: red;
    }

    40% {
        opacity: .5;
    }

    20% {
        opacity: .30;
    }

    from {
        opacity: 1;
        height: 30px;
    }
}

@keyframes leave {
    to {
        opacity: 0;
        height: 0px;
    }

    80% {
        opacity: .25;
    }

    60% {
        opacity: .10;
        color: red;
    }

    40% {
        opacity: .5;
    }

    20% {
        opacity: .30;
    }

    from {
        opacity: 1;
        height: 30px;
    }
}

.cert > li {
    opacity: 1;
    display: inline;
    color: black;
}

    .cert > li.ng-enter {
        -webkit-transition: 1s;
        transition: 1s;
        opacity: 0;
        -webkit-animation: enter 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
        animation: enter 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
        display: block;
        position: relative;
        background-color: #EE7F00;
        border-radius: 3px;
        color: white;
    }

    .cert > li.ng-enter-active {
        opacity: 1;
    }

.cert > div {
    opacity: 1;
    display: inline;
    color: black;
}

    .cert > div.ng-enter {
        -webkit-transition: 1s;
        transition: 1s;
        opacity: 0;
        -webkit-animation: enter 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
        animation: enter 1025ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
        display: block;
        position: relative;
        background-color: #EE7F00;
        border-radius: 3px;
        color: white;
    }

    .cert > div.ng-enter-active {
        opacity: 1;
    }

.dekra-menu > ul {
    list-style-type: none;
    width: 250px;
    border: 1px solid black;
    margin: 0;
    padding: 10px;
    font-size: 0;
}

    .dekra-menu > ul li {
        display: inline-block;
        *display: inline;
        zoom: 1;
    }

        .dekra-menu > ul li a {
            display: inline-block;
            padding: 10px;
            background: #E2E2E2;
            border: 1px solid #CCC;
            font-size: 13px;
        }

            .dekra-menu > ul li a:hover {
                background: white;
            }

.side-bar-choices {
    color: #007D40;
}

.group-panel {
}

.side-bar-choices > .panel-group > .panel-default {
    border: 0px;
    border-radius: 0px;
}

    .side-bar-choices > .panel-group > .panel-default > .panel-heading {
        background-color: white;
        border-top: 2px solid #F1F1F1;
        border-bottom: 2px solid #D1D1D1;
        border-radius: 0px;
    }

    .side-bar-choices > .panel-group > .panel-default > .panel-collapse > .panel-body {
        border-color: red;
        border-radius: 0px;
    }

    .side-bar-choices > .panel-group > .panel-default > .panel-collapse > .panel-body {
        border-color: white;
        border-radius: 0px;
    }

    .side-bar-choices > .panel-group > .panel-default > .panel-collapse > .collapse {
        border-color:blue;
        border-radius:0px;
    }

.capitalize {
    text-transform: capitalize
}


/*END Animation for certifikat choises, behörighet osv*/
.h4 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.label-step {
    background-color: #999999;
}

.label-step-selected {
    background-color: #EE7F00;
}


.dekra-combination-tabel {
    margin-top: 5px;
    padding: 0px;
    width: 100%;
    border: 3px double #333333;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
}

    .dekra-combination-tabel table {
        border-collapse: collapse;
        border-spacing: 0;
        border-top: 1px solid double black;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        margin-top: 5px;
    }

    .dekra-combination-tabel tr:last-child td:last-child {
        -moz-border-radius-bottomright: 2px;
        -webkit-border-bottom-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .dekra-combination-tabel table tr:first-child td:first-child {
        -moz-border-radius-topleft: 2px;
        -webkit-border-top-left-radius: 2px;
        border-top-left-radius: 2px;
    }

    .dekra-combination-tabel table tr:first-child td:last-child {
        -moz-border-radius-topright: 2px;
        -webkit-border-top-right-radius: 2px;
        border-top-right-radius: 2px;
    }

    .dekra-combination-tabel tr:last-child td:first-child {
        -moz-border-radius-bottomleft: 2px;
        -webkit-border-bottom-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    .dekra-combination-tabel tr:hover td {
    }

    .dekra-combination-tabel tr:nth-child(1n+2) > td:nth-child(2), .dekra-combination-tabel tr:nth-child(1n+3) > td:nth-child(3) {
        font-family: Bookman Old Style;
        color: black;
        font-weight: normal;
    }

    .dekra-combination-tabel tr:nth-child(1n+2) > td:last-child {
        font-family: Bookman Old Style;
        color: black;
        font-weight: normal;
    }

    .dekra-combination-tabel tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .dekra-combination-tabel tr:nth-child(even) {
        background-color: #ffffff;
    }

    .dekra-combination-tabel td {
        vertical-align: middle;
        border: 1px solid #333333;
        border-width: 1px 1px 1px 0px;
        text-align: left;
        padding: 9px;
        font-size: 10px;
        font-family: Arial;
        color: #000000;
    }

    .dekra-combination-tabel tr:last-child td {
        border-width: 1px 1px 0px 0px;
    }

    .dekra-combination-tabel tr td:last-child {
        border-width: 1px 0px 1px 0px;
    }

    .dekra-combination-tabel tr:last-child td:last-child {
        border-width: 1px 0px 0px 0px;
    }

    .dekra-combination-tabel tr td:first-child {
        font-weight: bold;
    }

    .dekra-combination-tabel .dekra-table-label {
        font-family: Arial !important;
        font-weight: bold !important;
    }

    .dekra-combination-tabel tr:first-child td {
        background: -o-linear-gradient(bottom, #ffffff 5%, #ffffff 100%);
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #ffffff) );
        background: -moz-linear-gradient( center top, #ffffff 5%, #ffffff 100% );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
        background: -o-linear-gradient(top,#ffffff,ffffff);
        background-color: #ffffff;
        border: 0px solid #333333;
        text-align: center;
        border-width: 1px 0px 1px 1px;
        font-size: 10px;
        font-family: Arial;
        font-weight: bold;
        color: #000000;
    }

    .dekra-combination-tabel tr:first-child:hover td {
        background: -o-linear-gradient(bottom, #ffffff 5%, #ffffff 100%);
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #ffffff) );
        background: -moz-linear-gradient( center top, #ffffff 5%, #ffffff 100% );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
        background: -o-linear-gradient(top,#ffffff,ffffff);
        background-color: #ffffff;
    }

    .dekra-combination-tabel tr:first-child td:first-child {
        border-width: 1px 0px 1px 0px;
    }

    .dekra-combination-tabel tr:first-child td:last-child {
        border-width: 1px 0px 1px 1px;
    }

    .dekra-combination-tabel .extra-class > td:nth-child(1) {
        font-family: Bookman Old Style;
        color: black;
        font-weight: normal;
    }

    .dekra-combination-tabel tr td:nth-child(2) {
        height: 31px;
    }

.extra-class tr td:nth-child(1) {
    height: 31px;
}

.dekra-combination-tabel .adj-height {
    height: 31px;
}
/* .dekra-combination-tabel  tr {
min-height:31px;
border:1px solid red;
} */
/*Area for range*/

.RangeTable {
    margin: 0px;
    padding: 0px;
    width: 100%;
    /*box-shadow: 10px 10px 5px #888888;*/
    border: 1px solid #ffffff;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
}

    .RangeTable table {
        border-collapse: collapse;
        border-spacing: 0;
        border-top: 0px solid double black;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }

    .RangeTable tr:last-child td:last-child {
        -moz-border-radius-bottomright: 2px;
        -webkit-border-bottom-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .RangeTable table tr:first-child td:first-child {
        -moz-border-radius-topleft: 2px;
        -webkit-border-top-left-radius: 2px;
        border-top-left-radius: 2px;
    }

    .RangeTable table tr:first-child td:last-child {
        -moz-border-radius-topright: 2px;
        -webkit-border-top-right-radius: 2px;
        border-top-right-radius: 2px;
    }

    .RangeTable tr:last-child td:first-child {
        -moz-border-radius-bottomleft: 2px;
        -webkit-border-bottom-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    .RangeTable tr:hover td {
    }

    .RangeTable tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .RangeTable tr:nth-child(even) {
        background-color: #F0F0F0;
    }

    .RangeTable td {
        vertical-align: middle;
        border: 1px solid #ffffff;
        border-width: 0px 1px 1px 0px;
        text-align: left;
        padding: 5px;
        padding-left: 0px;
        font-size: 11px;
        font-family: Arial;
        font-weight: normal;
        color: #000000;
    }

    .RangeTable tr:last-child td {
        border-width: 0px 1px 0px 0px;
    }

    .RangeTable tr td:last-child {
        border-width: 0px 0px 1px 0px;
    }

    .RangeTable tr:last-child td:last-child {
        border-width: 0px 0px 0px 0px;
    }

    .RangeTable tr:first-child td {
        background: -o-linear-gradient(bottom, #ffffff 5%, #ffffff 100%);
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #ffffff) );
        background: -moz-linear-gradient( center top, #ffffff 5%, #ffffff 100% );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
        background: -o-linear-gradient(top,#ffffff,ffffff);
        background-color: #ffffff;
        border: 1px solid #ffffff;
        text-align: center;
        border-width: 0px 0px 1px 1px;
        font-size: 9px;
        font-family: Arial;
        font-weight: bold;
        color: #000000;
    }

    .RangeTable tr:first-child:hover td {
        background: -o-linear-gradient(bottom, #ffffff 5%, #ffffff 100%);
        background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #ffffff) );
        background: -moz-linear-gradient( center top, #ffffff 5%, #ffffff 100% );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff");
        background: -o-linear-gradient(top,#ffffff,ffffff);
        background-color: #ffffff;
    }

    .RangeTable tr:first-child td:first-child {
        border-width: 0px 0px 1px 0px;
    }

    .RangeTable tr:first-child td:last-child {
        border-width: 0px 0px 1px 1px;
    }
/*Area*/
.gray-gradient-bg {
    background: rgba(237,237,237,1);
    background: -moz-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 47%, rgba(245,245,245,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(237,237,237,1)), color-stop(47%, rgba(246,246,246,1)), color-stop(100%, rgba(245,245,245,1)));
    background: -webkit-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 47%, rgba(245,245,245,1) 100%);
    background: -o-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 47%, rgba(245,245,245,1) 100%);
    background: -ms-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 47%, rgba(245,245,245,1) 100%);
    background: linear-gradient(to bottom, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 47%, rgba(245,245,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#f5f5f5', GradientType=0 );
    /*background: linear-gradient(#F1F1F1, #D1D1D1);*/
}

.green-gradient-bg {
    /*background: rgba(69,163,88,1);
background: -moz-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(69,163,88,1)), color-stop(20%, rgba(69,163,88,1)), color-stop(26%, rgba(69,163,88,1)), color-stop(55%, rgba(0,125,65,1)), color-stop(100%, rgba(0,125,65,1)));
background: -webkit-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -o-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -ms-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: linear-gradient(to bottom, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45a358', endColorstr='#007d41', GradientType=0 );*/
    background: linear-gradient(#45A358, #007D40);
}

/*navigation in examination wpqr tab*/
.d-examination-nav-tabs .nav-link.active, .d-examination-nav-tabs .nav-item.show .nav-link {
    color: #FFFFFF;
    background: linear-gradient(#45A358, #007D40);
}
.d-examination-nav-tabs > li > a {
    background: linear-gradient(#F1F1F1, #D1D1D1);
    border: 0px solid transparent !important;
}

/*top navigation*/
.dekra-renewal-tabs.dekra-nav-tabs > li > a {
    font-size: 12px;
}

.dekra-nav-tabs > li > a {
    font-size: 14px;
    /*background: rgba(226,226,226,1);
background: -moz-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(226,226,226,1)), color-stop(50%, rgba(219,219,219,1)), color-stop(51%, rgba(209,209,209,1)), color-stop(100%, rgba(254,254,254,1)));
background: -webkit-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -o-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -ms-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: linear-gradient(to bottom, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0 );*/
    background: linear-gradient(#F1F1F1, #D1D1D1);
    border-bottom: 1px solid transparent;
}

.dekra-nav-tabs > li.active > a, .dekra-nav-tabs > li.active > a:hover, .dekra-nav-tabs > li.active > a:focus {
    color: #FFFFFF;
    cursor: default;
    background: linear-gradient(#45A358, #007D40);
    /*
background: rgba(69,163,88,1);
background: -moz-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(69,163,88,1)), color-stop(20%, rgba(69,163,88,1)), color-stop(26%, rgba(69,163,88,1)), color-stop(55%, rgba(0,125,65,1)), color-stop(100%, rgba(0,125,65,1)));
background: -webkit-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -o-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: -ms-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
background: linear-gradient(to bottom, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45a358', endColorstr='#007d41', GradientType=0 );*/
}

.dekra-green-active > li.active > a,
.dekra-green-active > li.active > a:hover,
.dekra-green-active > li.active > a:focus {
    color: #007D40;
}

.dekra-formval-msg {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #EE7F00;
    font-style: italic;
}

.dekra-settings-label-containers, .dekra-settings-input-containers {
    width: 9em;
    float: left;
    margin-right: 15px;
}

.dekra-settings-input-containers {
    width: 18em;
}

@media screen and (max-width: 768px) {
    .dekra-settings-label-containers, .dekra-settings-input-containers {
        width: 100%;
        clear: both;
    }
}

.dekra-no-bottom-margin {
    margin: 0 !important;
}

.box {
    margin: 10px;
    padding-bottom: 10px;
}

    .box .inner {
        background: #f1f1f1;
        padding: 10px;
        border-radius: 4px;
        margin-left: -25px;
        margin-top: -10px;
    }

.dekra-status-check {
    position: relative !important;
    float: left !important;
    width: 45px !important;
    display: block !important;
    margin: 0 0px !important;
    padding: 0 0px !important;
    height: 100% !important;
}

.dekra-status-plupp {
    position: relative !important;
    float: left !important;
    width: 15px !important;
    margin: 0 5px 0 0 !important;
    padding: 0 0 !important;
    height: 100% !important;
    display: block !important;
}

.dekra-center {
    width: 65px;
    margin: 0 auto;
}

.dekra-td-status {
    padding: 0 0;
}

.dekra-table-settings-btn {
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
    background: transparent !important;
    color: #007D40;
    border-color: transparent;
}
.dekra-table-settings-btn:hover {
    color: #555;
    border-color: transparent;
}

.dekra2-table-settings-btn {
    float: right;
    color: #007D40;
    border-color: transparent;
}
.dekra2-table-settings-btn:hover {
    color: #555;
    border-color: transparent;
}

.dekra-export-btn-group {
    float: left;
}
/* Medium Devices, Desktops */
@media only screen and (min-width : 768px) {
    .dekra-export-btn-group {
        float: right;
    }
}

.dekra-forlang-betecknings-rad,
.dekra-forlang-refnr-rad {
    position: relative;
    float: left;
}

.dekra-done-refnr-rad {
    position: relative;
    float: left;
    clear: both;
    margin-right: 15px;
}

.--radio-alignment {
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: 1;
}

@media (max-width:1238px) and (min-width:1200px) {
    .--sm-small-font {
        font-size: 13px;
    }
}

@media ( max-width:1500px) {
    .wider-input-small-device .form-group > .col-xs-6.col-sm-4, .wider-input-small-device .form-group > .col-xs-6.col-sm-3, .wider-input-small-device .form-group > .col-xs-6.col-sm-2, .wider-input-small-device .form-group > .col-xs-3, .wider-input-small-device .form-group > .col-sm-3, .wider-input-small-device .form-group > .col-sm-2, .wider-input-small-device .form-group > .col-sm-4 {
        width: 50% !important;
    }

    .dekra-hide-sm-screens {
        display: none;
    }
}

.img-responsive {
    width: 100%;
}
/*formvalidation*/
.dekra-red {
    color: #BA0101;
}

.dekra-renewal-header-h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    display: block;
    float: left;
}

.dekra-renewal-header-mark-all {
    margin-top: 3px;
    margin-left: 10px;
    float: left;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    color: #007D40;
}

/*Förlängningar*/
.dekra-duedays {
    float: right;
}

@media screen and (max-width:1490px) and (min-width: 991px) {
    .dekra-duedays {
        margin-left: 0px;
        float: left;
    }
}

@media ( max-width:767px) {
    .dekra-duedays {
        margin-left: 150px;
        float: left;
    }
}

@media ( max-width:675px) {
    .dekra-duedays {
        margin-left: 0px;
        float: left;
    }
}

.dekra-forlangningar .glyphicon-calendar {
    margin-left: 0 !important;
}

.dekra-table-display .table-dekra td,
.dekra-table-display .dekra-combination-tabel td,
.dekra-table-display .table-dekra .grey-bg > td:nth-child(2),
.dekra-table-display .table-dekra .grey-bg > td:nth-child(3) {
    vertical-align: text-top;
}

.striped {
    background: repeating-linear-gradient( -55deg, #f0f0f0, #f0f0f0 10px, #FFF 10px, #FFF 20px );
}

.striped-prolongations {
    background-image: repeating-linear-gradient( -55deg, rgba(240,240,240, 0.5), rgba(240,240,240, 0.6) 10px, rgba(255,255,255, 0.5) 10px, rgba(255,255,255, 0.6) 20px );
}

.striped td {
    background: transparent;
}

.inactive-cert-row td {
    text-decoration: line-through;
}

@media (min-width: 990px) and (max-width: 1150px) {
    .dekra-giltighet-text {
        display: none;
    }
    /* hide it elsewhere */
}

.full button span {
    background-color: limegreen;
    border-radius: 32px;
    color: black;
}

.partially button span {
    background-color: orange;
    border-radius: 32px;
    color: black;
}

@media screen and (max-width: 1200px) {
    .dekra-prolongations-view {
        width: 100% !important;
        min-width: 100%;
        max-width: 100%;
    }
}

.dekra-pdf-wrapper {
    width: 80px;
}

p.dekra-pdf-element1 {
    width: 20px;
    padding-top: 2.5px;
    margin-left: 10px;
    float: left;
    position: relative;
    vertical-align: middle;
    line-height: 30px;
    height: 30px;
}

p.dekra-pdf-element2 {
    width: 38px;
    padding-top: 2.5px;
    margin-left: 10px;
    float: left;
    position: relative;
    vertical-align: middle;
    line-height: 30px;
    height: 30px;
}

.dekra-menu-table-select {
    width: 49%;
    float: left;
    position: relative;
}

    .dekra-menu-table-select:nth-child(odd) {
        margin-right: 2%;
    }

@media screen and (min-width: 1200px) {

    .dekra-menu-table-select {
        width: 24%;
        margin-right: 1%;
        float: left;
        position: relative;
    }

        .dekra-menu-table-select:nth-child(odd) {
            margin-right: 1%;
        }
}

@media screen and (max-width: 1200px) {

    .dekra-fullwith-table-menu {
        width: 100%;
    }
}

.thinCol {
    width: 10px;
}