/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.active-tab {
    background-color: lawngreen;
}


.loader-modal {
    width: 10vw;
    max-width: 10vw;
}

.scroll-vertical {
    overflow-y: auto;
    width: 100%;
    height: 200px;
    position: absolute;
    z-index: 10000;
}

.scroll-vertical-width {
    overflow-y: auto;
    width: 55%;
    height: 200px;
    position: absolute;
    z-index: 10000;
}

.applications-table-header-row {
    color: red;
    cursor: pointer;
}

.draw-joint-design-container {
    position: relative;
    text-align: center;
    color: white;
}

.html-to-image {
    /*display: inline-block;*/
    background: white;
}

.image-auto-with-height {
    width: 160px;
    height: 100px;
}

.image-auto-with {
    /*width: 100%;*/
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-draw-joint-design {
    width: 100%;
    height: auto;
    max-height: 210px;
    max-width: 500px;
}

.image-weld-sequence {
    width: 100%;
    height: auto;
    max-height: 210px;
    max-width: 500px;
}

.i-joint-7-material-thickness1 {
    position: absolute;
    left: 640px;
    bottom: 115px;
    color: black;
}

.i-joint-7-gap-opening {
    position: absolute;
    left: 325px;
    bottom: 5px;
    color: black;
}



.j-joint-15-material-thickness1 {
    position: absolute;
    left: 530px;
    bottom: 140px;
    color: black;
}

.j-joint-15-phase-angle-1 {
    position: absolute;
    left: 315px;
    bottom: 275px;
    color: black;
}

.j-joint-15-gap-opening {
    position: absolute;
    left: 277px;
    bottom: 0px;
    color: black;
}

.j-joint-15-straight-edge {
    position: absolute;
    left: 470px;
    bottom: 75px;
    color: black;
}



.k-joint-11-material-thickness1 {
    position: absolute;
    left: 540px;
    bottom: 80px;
    color: black;
}

.k-joint-11-phase-angle-1 {
    position: absolute;
    left: 375px;
    bottom: 175px;
    color: black;
}

.k-joint-11-gap-opening {
    position: absolute;
    left: 355px;
    bottom: 5px;
    color: black;
}

.k-joint-11-straight-edge {
    position: absolute;
    left: 500px;
    bottom: 45px;
    color: black;
}

.k-joint-15-material-thickness1 {
    position: absolute;
    left: 535px;
    bottom: 80px;
    color: black;
}

.k-joint-15-phase-angle-1 {
    position: absolute;
    left: 375px;
    bottom: 175px;
    color: black;
}

.k-joint-15-gap-opening {
    position: absolute;
    left: 360px;
    bottom: 5px;
    color: black;
}

.k-joint-15-straight-edge {
    position: absolute;
    left: 495px;
    bottom: 45px;
    color: black;
}



.k-joint-asymmetric-15-against-11-material-thickness1 {
    position: absolute;
    left: 545px;
    bottom: 80px;
    color: black;
}

.k-joint-asymmetric-15-against-11-material-thickness2 {
    position: absolute;
    left: 170px;
    bottom: 80px;
    color: black;
}

.k-joint-asymmetric-15-against-11-phase-angle-1 {
    position: absolute;
    left: 375px;
    bottom: 175px;
    color: black;
}

.k-joint-asymmetric-15-against-11-gap-opening {
    position: absolute;
    left: 357px;
    bottom: 0px;
    color: black;
}

.k-joint-asymmetric-15-against-11-straight-edge {
    position: absolute;
    left: 500px;
    bottom: 40px;
    color: black;
}



.k-joint-t-joint-11-material-thickness1 {
    position: absolute;
    left: 520px;
    bottom: 125px;
    color: black;
}

.k-joint-t-joint-11-phase-angle-1 {
    position: absolute;
    left: 300px;
    bottom: 255px;
    color: black;
}

.k-joint-t-joint-11-gap-opening {
    position: absolute;
    left: 272px;
    bottom: 0px;
    color: black;
}

.k-joint-t-joint-11-straight-edge {
    position: absolute;
    left: 460px;
    bottom: 85px;
    color: black;
}

.k-joint-t-joint-15-material-thickness1 {
    position: absolute;
    left: 525px;
    bottom: 140px;
    color: black;
}

.k-joint-t-joint-15-phase-angle-1 {
    position: absolute;
    left: 300px;
    bottom: 270px;
    color: black;
}

.k-joint-t-joint-15-gap-opening {
    position: absolute;
    left: 272px;
    bottom: 0px;
    color: black;
}

.k-joint-t-joint-15-straight-edge {
    position: absolute;
    left: 465px;
    bottom: 85px;
    color: black;
}



.u-joint-11-material-thickness1 {
    position: absolute;
    left: 580px;
    bottom: 97px;
    color: black;
}

.u-joint-11-phase-angle-1 {
    position: absolute;
    left: 350px;
    bottom: 200px;
    color: black;
}

.u-joint-11-gap-opening {
    position: absolute;
    left: 315px;
    bottom: 5px;
    color: black;
}

.u-joint-11-straight-edge {
    position: absolute;
    left: 507px;
    bottom: 40px;
    color: black;
}

.u-joint-15-material-thickness1 {
    position: absolute;
    left: 575px;
    bottom: 110px;
    color: black;
}

.u-joint-15-phase-angle-1 {
    position: absolute;
    left: 350px;
    bottom: 215px;
    color: black;
}

.u-joint-15-gap-opening {
    position: absolute;
    left: 315px;
    bottom: 5px;
    color: black;
}

.u-joint-15-straight-edge {
    position: absolute;
    left: 507px;
    bottom: 40px;
    color: black;
}

.v-joint-7-material-thickness1 {
    position: absolute;
    left: 545px;
    bottom: 70px;
    color: black;
}

.v-joint-7-joint-angle-alpha {
    position: absolute;
    left: 325px;
    bottom: 140px;
    color: black;
}

.v-joint-7-gap-opening {
    position: absolute;
    left: 330px;
    bottom: 0px;
    color: black;
}

.v-joint-7-straight-edge {
    position: absolute;
    left: 495px;
    bottom: 30px;
    color: black;
}

.v-joint-11-material-thickness1 {
    position: absolute;
    left: 545px;
    bottom: 87px;
    color: black;
}

.v-joint-11-joint-angle-alpha {
    position: absolute;
    left: 325px;
    bottom: 162px;
    color: black;
}

.v-joint-11-gap-opening {
    position: absolute;
    left: 330px;
    bottom: 5px;
    color: black;
}

.v-joint-11-straight-edge {
    position: absolute;
    left: 495px;
    bottom: 35px;
    color: black;
}

.v-joint-15-material-thickness1 {
    position: absolute;
    left: 540px;
    bottom: 95px;
    color: black;
}

.v-joint-15-joint-angle-alpha {
    position: absolute;
    left: 325px;
    bottom: 185px;
    color: black;
}

.v-joint-15-gap-opening {
    position: absolute;
    left: 330px;
    bottom: 5px;
    color: black;
}

.v-joint-15-straight-edge {
    position: absolute;
    left: 495px;
    bottom: 40px;
    color: black;
}

.v-joint-half-7-material-thickness1 {
    position: absolute;
    left: 545px;
    bottom: 67px;
    color: black;
}

.v-joint-half-7-phase-angle-1 {
    position: absolute;
    left: 350px;
    bottom: 145px;
    color: black;
}

.v-joint-half-7-gap-opening {
    position: absolute;
    left: 327px;
    bottom: -5px;
    color: black;
}

.v-joint-half-7-straight-edge {
    position: absolute;
    left: 497px;
    bottom: 25px;
    color: black;
}

.v-joint-half-11-material-thickness1 {
    position: absolute;
    left: 540px;
    bottom: 75px;
    color: black;
}

.v-joint-half-11-phase-angle-1 {
    position: absolute;
    left: 350px;
    bottom: 150px;
    color: black;
}

.v-joint-half-11-gap-opening {
    position: absolute;
    left: 327px;
    bottom: -5px;
    color: black;
}

.v-joint-half-11-straight-edge {
    position: absolute;
    left: 492px;
    bottom: 25px;
    color: black;
}

.v-joint-half-15-material-thickness1 {
    position: absolute;
    left: 535px;
    bottom: 85px;
    color: black;
}

.v-joint-half-15-phase-angle-1 {
    position: absolute;
    left: 355px;
    bottom: 175px;
    color: black;
}

.v-joint-half-15-gap-opening {
    position: absolute;
    left: 327px;
    bottom: -5px;
    color: black;
}

.v-joint-half-15-straight-edge {
    position: absolute;
    left: 492px;
    bottom: 25px;
    color: black;
}

.v-joint-half-t-joint-7-material-thickness1 {
    position: absolute;
    left: 465px;
    bottom: 75px;
    color: black;
}

.v-joint-half-t-joint-7-phase-angle-1 {
    position: absolute;
    left: 325px;
    bottom: 150px;
    color: black;
}

.v-joint-half-t-joint-7-gap-opening {
    position: absolute;
    left: 330px;
    bottom: 10px;
    color: black
}

.v-joint-half-t-joint-7-straight-edge {
    position: absolute;
    left: 427px;
    bottom: 40px;
    color: black;
}

.v-joint-half-t-joint-11-material-thickness1 {
    position: absolute;
    left: 460px;
    bottom: 82px;
    color: black;
}

.v-joint-half-t-joint-11-phase-angle-1 {
    position: absolute;
    left: 330px;
    bottom: 155px;
    color: black;
}

.v-joint-half-t-joint-11-gap-opening {
    position: absolute;
    left: 325px;
    bottom: 10px;
    color: black;
}

.v-joint-half-t-joint-11-straight-edge {
    position: absolute;
    left: 427px;
    bottom: 40px;
    color: black;
}

.v-joint-half-t-joint-15-material-thickness1 {
    position: absolute;
    left: 455px;
    bottom: 85px;
    color: black;
}

.v-joint-half-t-joint-15-phase-angle-1 {
    position: absolute;
    left: 330px;
    bottom: 175px;
    color: black;
}

.v-joint-half-t-joint-15-gap-opening {
    position: absolute;
    left: 325px;
    bottom: 10px;
    color: black;
}

.v-joint-half-t-joint-15-straight-edge {
    position: absolute;
    left: 420px;
    bottom: 40px;
    color: black;
}

/***/

.v-joint-asymmetric-half-t-joint-15-against-7-material-thickness1 {
    position: absolute;
    left: 477px;
    bottom: 110px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-7-material-thickness2 {
    position: absolute;
    left: 320px;
    bottom: 8px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-7-phase-angle-1 {
    position: absolute;
    left: 320px;
    bottom: 180px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-7-gap-opening {
    position: absolute;
    left: 325px;
    bottom: 37px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-7-straight-edge {
    position: absolute;
    left: 435px;
    bottom: 70px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-11-material-thickness1 {
    position: absolute;
    left: 485px;
    bottom: 117px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-11-material-thickness2 {
    position: absolute;
    left: 310px;
    bottom: 10px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-11-phase-angle-1 {
    position: absolute;
    left: 320px;
    bottom: 200px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-11-gap-opening {
    position: absolute;
    left: 325px;
    bottom: 42px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-11-straight-edge {
    position: absolute;
    left: 435px;
    bottom: 70px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-15-material-thickness1 {
    position: absolute;
    left: 480px;
    bottom: 132px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-15-material-thickness2 {
    position: absolute;
    left: 310px;
    bottom: 10px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-15-phase-angle-1 {
    position: absolute;
    left: 325px;
    bottom: 220px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-15-gap-opening {
    position: absolute;
    left: 325px;
    bottom: 50px;
    color: black;
}

.v-joint-asymmetric-half-t-joint-15-against-15-straight-edge {
    position: absolute;
    left: 435px;
    bottom: 85px;
    color: black;
}



.v-joint-high-straight-edge-11-material-thickness1 {
    position: absolute;
    left: 545px;
    bottom: 87px;
    color: black;
}

.v-joint-high-straight-edge-11-joint-angle-alpha {
    position: absolute;
    left: 325px;
    bottom: 170px;
    color: black;
}

.v-joint-high-straight-edge-11-gap-opening {
    position: absolute;
    left: 335px;
    bottom: 5px;
    color: black;
}

.v-joint-high-straight-edge-11-straight-edge {
    position: absolute;
    left: 500px;
    bottom: 35px;
    color: black;
}

.v-joint-high-straight-edge-15-material-thickness1 {
    position: absolute;
    left: 545px;
    bottom: 100px;
    color: black;
}

.v-joint-high-straight-edge-15-joint-angle-alpha {
    position: absolute;
    left: 325px;
    bottom: 185px;
    color: black;
}

.v-joint-high-straight-edge-15-gap-opening {
    position: absolute;
    left: 335px;
    bottom: 10px;
    color: black;
}

.v-joint-high-straight-edge-15-straight-edge {
    position: absolute;
    left: 490px;
    bottom: 35px;
    color: black;
}



.x-fog-7-material-thickness1 {
    position: absolute;
    left: 570px;
    bottom: 70px;
    color: black;
}

.x-fog-7-joint-angle-alpha {
    position: absolute;
    left: 335px;
    bottom: 140px;
    color: black;
}

.x-fog-7-joint-angle-beta {
    position: absolute;
    left: 332px;
    bottom: 18px;
    color: black;
}

.x-fog-7-gap-opening {
    position: absolute;
    left: 510px;
    bottom: 35px;
    color: black;
}

.x-fog-7-depth-of-preparation {
    position: absolute;
    left: 170px;
    bottom: 21px;
    color: black;
}

.x-fog-11-material-thickness1 {
    position: absolute;
    left: 565px;
    bottom: 80px;
    color: black;
}

.x-fog-11-joint-angle-alpha {
    position: absolute;
    left: 330px;
    bottom: 160px;
    color: black;
}

.x-fog-11-joint-angle-beta {
    position: absolute;
    left: 330px;
    bottom: -5px;
    color: black;
}

.x-fog-11-gap-opening {
    position: absolute;
    left: 510px;
    bottom: 40px;
    color: black;
}

.x-fog-11-depth-of-preparation {
    position: absolute;
    left: 154px;
    bottom: 21px;
    color: black;
}

.x-fog-15-material-thickness1 {
    position: absolute;
    left: 575px;
    bottom: 90px;
    color: black;
}

.x-fog-15-joint-angle-alpha {
    position: absolute;
    left: 330px;
    bottom: 180px;
    color: black;
}

.x-fog-15-joint-angle-beta {
    position: absolute;
    left: 330px;
    bottom: -5px;
    color: black;
}

.x-fog-15-gap-opening {
    position: absolute;
    left: 510px;
    bottom: 45px;
    color: black;
}

.x-fog-15-depth-of-preparation {
    position: absolute;
    left: 150px;
    bottom: 28px;
    color: black;
}


.x-fog-asymetrisk-7-material-thickness1 {
    position: absolute;
    left: 680px;
    bottom: 110px;
    color: black;
}

.x-fog-asymetrisk-7-joint-angle-alpha {
    position: absolute;
    left: 350px;
    bottom: 210px;
    color: black;
}

.x-fog-asymetrisk-7-joint-angle-beta {
    position: absolute;
    left: 350px;
    bottom: 0px;
    color: black;
}

.x-fog-asymetrisk-7-gap-opening {
    position: absolute;
    left: 595px;
    bottom: 60px;
    color: black;
}

.x-fog-asymetrisk-7-depth-of-preparation {
    position: absolute;
    left: 110px;
    bottom: 50px;
    color: black;
}

.x-fog-asymetrisk-11-material-thickness1 {
    position: absolute;
    left: 675px;
    bottom: 120px;
    color: black;
}

.x-fog-asymetrisk-11-joint-angle-alpha {
    position: absolute;
    left: 350px;
    bottom: 250px;
    color: black;
}

.x-fog-asymetrisk-11-joint-angle-beta {
    position: absolute;
    left: 350px;
    bottom: 0px;
    color: black;
}

.x-fog-asymetrisk-11-gap-opening {
    position: absolute;
    left: 595px;
    bottom: 65px;
    color: black;
}

.x-fog-asymetrisk-11-depth-of-preparation {
    position: absolute;
    left: 97px;
    bottom: 50px;
    color: black;
}

.x-fog-asymetrisk-15-material-thickness1 {
    position: absolute;
    left: 675px;
    bottom: 145px;
    color: black;
}

.x-fog-asymetrisk-15-joint-angle-alpha {
    position: absolute;
    left: 350px;
    bottom: 265px;
    color: black;
}

.x-fog-asymetrisk-15-joint-angle-beta {
    position: absolute;
    left: 350px;
    bottom: 5px;
    color: black;
}

.x-fog-asymetrisk-15-gap-opening {
    position: absolute;
    left: 595px;
    bottom: 80px;
    color: black;
}

.x-fog-asymetrisk-15-depth-of-preparation {
    position: absolute;
    left: 100px;
    bottom: 61px;
    color: black;
}


.y-joint-7-material-thickness1 {
    position: absolute;
    left: 555px;
    bottom: 95px;
    color: black;
}

.y-joint-7-joint-angle-alpha {
    position: absolute;
    left: 300px;
    bottom: 185px;
    color: black;
}

.y-joint-7-gap-opening {
    position: absolute;
    left: 305px;
    bottom: 5px;
    color: black;
}

.y-joint-7-straight-edge {
    position: absolute;
    left: 505px;
    bottom: 45px;
    color: black;
}

.y-joint-11-material-thickness1 {
    position: absolute;
    left: 555px;
    bottom: 90px;
    color: black;
}

.y-joint-11-joint-angle-alpha {
    position: absolute;
    left: 300px;
    bottom: 175px;
    color: black;
}

.y-joint-11-gap-opening {
    position: absolute;
    left: 307px;
    bottom: 5px;
    color: black;
}

.y-joint-11-straight-edge {
    position: absolute;
    left: 497px;
    bottom: 35px;
    color: black;
}

.y-joint-15-material-thickness1 {
    position: absolute;
    left: 540px;
    bottom: 95px;
    color: black;
}

.y-joint-15-joint-angle-alpha {
    position: absolute;
    left: 300px;
    bottom: 197px;
    color: black;
}

.y-joint-15-gap-opening {
    position: absolute;
    left: 305px;
    bottom: 0px;
    color: black;
}

.y-joint-15-straight-edge {
    position: absolute;
    left: 492px;
    bottom: 35px;
    color: black;
}

.k-welding-corner-7-gap-opening {
    position: absolute;
    left: 262px;
    bottom: 26px;
    color: black;
}

.k-welding-corner-7-material-thickness1 {
    position: absolute;
    left: 450px;
    bottom: 34px;
    color: black;
}

.k-welding-corner-7-material-thickness2 {
    position: absolute;
    left: 373px;
    bottom: 135px;
    color: black;
}
/***/
.k-welding-corner-11-gap-opening {
    position: absolute;
    left: 258px;
    bottom: 24px;
    color: black;
}

.k-welding-corner-11-material-thickness1 {
    position: absolute;
    left: 450px;
    bottom: 38px;
    color: black;
}

.k-welding-corner-11-material-thickness2 {
    position: absolute;
    left: 376px;
    bottom: 140px;
    color: black;
}
/***/
.k-welding-corner-15-gap-opening {
    position: absolute;
    left: 256px;
    bottom: 26px;
    color: black;
}

.k-welding-corner-15-material-thickness1 {
    position: absolute;
    left: 456px;
    bottom: 46px;
    color: black;
}

.k-welding-corner-15-material-thickness2 {
    position: absolute;
    left: 388px;
    bottom: 146px;
    color: black;
}
/***/
.k-welding-t-7-gap-opening {
    position: absolute;
    left: 412px;
    bottom: 55px;
    color: black;
}

.k-welding-t-7-material-thickness1 {
    position: absolute;
    left: 458px;
    bottom: 24px;
    color: black;
}

.k-welding-t-7-material-thickness2 {
    position: absolute;
    left: 360px;
    bottom: 130px;
    color: black;
}
/***/
.k-welding-t-11-gap-opening {
    position: absolute;
    left: 408px;
    bottom: 64px;
    color: black;
}

.k-welding-t-11-material-thickness1 {
    position: absolute;
    left: 452px;
    bottom: 24px;
    color: black;
}

.k-welding-t-11-material-thickness2 {
    position: absolute;
    left: 356px;
    bottom: 148px;
    color: black;
}
/***/
.k-welding-t-15-gap-opening {
    position: absolute;
    left: 420px;
    bottom: 75px;
    color: black;
}

.k-welding-t-15-material-thickness1 {
    position: absolute;
    left: 456px;
    bottom: 30px;
    color: black;
}

.k-welding-t-15-material-thickness2 {
    position: absolute;
    left: 366px;
    bottom: 156px;
    color: black;
}
/***/
.k-welding-corner-asymmetric-3-gap-opening {
    position: absolute;
    left: 448px;
    bottom: 50px;
    color: black;
}

.k-welding-corner-asymmetric-3-material-thickness1 {
    position: absolute;
    left: 254px;
    bottom: 28px;
    color: black;
}

.k-welding-corner-asymmetric-3-material-thickness2 {
    position: absolute;
    left: 361px;
    bottom: 154px;
    color: black;
}
/***/
.k-welding-corner-asymmetric-7-gap-opening {
    position: absolute;
    left: 448px;
    bottom: 50px;
    color: black;
}

.k-welding-corner-asymmetric-7-material-thickness1 {
    position: absolute;
    left: 253px;
    bottom: 26px;
    color: black;
}

.k-welding-corner-asymmetric-7-material-thickness2 {
    position: absolute;
    left: 370px;
    bottom: 146px;
    color: black;
}
/***/
.k-welding-t-asymmetric-3-gap-opening {
    position: absolute;
    left: 430px;
    bottom: 72px;
    color: black;
}

.k-welding-t-asymmetric-3-material-thickness1 {
    position: absolute;
    left: 465px;
    bottom: 29px;
    color: black;
}

.k-welding-t-asymmetric-3-material-thickness2 {
    position: absolute;
    left: 365px;
    bottom: 156px;
    color: black;
}
/***/
.k-welding-t-asymmetric-7-gap-opening {
    position: absolute;
    left: 420px;
    bottom: 64px;
    color: black;
}

.k-welding-t-asymmetric-7-material-thickness1 {
    position: absolute;
    left: 458px;
    bottom: 24px;
    color: black;
}

.k-welding-t-asymmetric-7-material-thickness2 {
    position: absolute;
    left: 370px;
    bottom: 145px;
    color: black;
}
/***/

/******k-welding End*******/
.field-flex {
    display: flex;
    justify-content: space-between;
}

.field-label {
    height: 28px;
    line-height: 28px;
}

.field-input {
    max-width: 200px;
}

.no-padding {
    padding: 0;
}

.modal-distancer {
    margin-bottom: 2px;
}

.standard-text {
    color: rgb(85, 85, 85);
    font-weight: 400;
}

.form-control {
    display: block;
    width: 100%;
    height: 28px;
    padding: 6px 12px 6px;
    font-size: 12px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

    .form-control:focus {
        border-color: gray;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(209,209,209, 0.8);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(209,209,209, 0.8);
    }

    .form-control::-moz-placeholder {
        color: #999;
        opacity: 1;
    }

    .form-control:-ms-input-placeholder {
        color: #999;
    }

    .form-control::-webkit-input-placeholder {
        color: #999;
    }

    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
        cursor: not-allowed;
        background-color: #eee;
        opacity: 1;
    }

label, th {
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
}

label {
    display: inline-block;
    margin-bottom: 2.5px;
    color: #007D40;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}