/**************Start *****************/
.img-wrap {
    position: relative;
    display: inline-block;
    border: 1px white solid;
    font-size: 0;
}

.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color: #FFF;
    padding: 5px 2px 2px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    opacity: .2;
    text-align: center;
    font-size: 22px;
    line-height: 10px;
    border-radius: 50%;
}

.img-wrap:hover .close {
    opacity: 1;
}
/**************End *****************/
/**************Start *****************/
.btn-group-vertical-unset {
    align-items: unset;
}
/**************End *****************/
/**************Start *****************/
.td-vertical-align > td {
    vertical-align: middle !important;
}
/**************End *****************/
/**************Start *****************/
.form-check-input-margin-top {
    margin-top: 0rem !important;
}

.form-check-input-margin-top-2 {
    margin-top: 0.2rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.2rem;
    margin-left: -1.25rem;
}
/**************End *****************/
/**************Start *****************/
.customer-logo-border {
    border: 2px solid #dee2e6;
}
.customer-logo {
    max-width: 210px;
    max-height: 100px;
}
/**************End *****************/

/**************Start *****************/
.fa-comments-color {
    color: #ff0000 !important;
}
/**************End *****************/

/**************Start *****************/
.home-contact-font-size {
    font-size: 12px;
}
/**************End *****************/

/**************Start *****************/
.textarea-delete-borders {
    border: none !important;
    box-shadow: none !important;
    outline: none;
    background: #fff;
    cursor: default;
    resize: none;
    padding-left: 0px !important;
    padding-top: 0px !important;
}
/**************End *****************/

/**************Start *****************/
.label-sm-form-size {
    font-size: 10px !important;
}
.label-sm-form-size-11 {
    font-size: 11px !important;
}
/**************End *****************/

/**************Start *****************/

.margin-bottom {
    margin-bottom: 1px;
}

.table-text-align-center {
    text-align: center;
}
.btn-primary-background {
    border-color: rgb(204, 204, 204) !important;
    background: linear-gradient(#f8f9fa, rgb(159 171 165)) !important;
}

.impact-testing-td {
    text-align: center;
    padding: 4px !important;
}
/**************End *****************/

/**************Start *****************/
.enable-base-material-text {
    border: 1px solid #007d40 !important;
}
.enable-make-text {
    border: 1px solid #007d40 !important;
}
.enable-diameter-text {
    border: 1px solid #007d40 !important;
}

.enable-materialThickness-text {
    border: 1px solid #007d40 !important;
}
/**************End *****************/

/**************Start Scrollbar*****************/
.scrollbar {
    overflow: scroll;
}

    .scrollbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background: green;
    }

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
    background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: green;
}
/**************End Scrollbar*****************/

/**************Start FileUpload*****************/
.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
/**************End FileUpload*****************/

/**************Start WParameters*****************/
.padding-form-control {
    padding: 1px 3px 1px !important;
    text-align: center !important;
}
.padding-form-control-select {
    padding: 1px 3px 1px !important;
}
.padding-width-form-control {
    width: auto !important;
    padding: 1px 1px 1px !important;
}
.pulse-td-dark-green {
    background: linear-gradient(#497741, #497741) !important;
}
.pulse-td-light-green {
    background: linear-gradient(#54d73df7, #54d73df7 ) !important;
}
/**************End WParameters*****************/

.h5_margin-bottom_0 {
    margin-bottom: 0px;
}

p {
    margin-top: 0;
    margin-bottom: 0px;
}
.tooltip-inner {
    /*background-color: #007D40 !important;*/
    background: linear-gradient(#45A358, #007D40);
}
.tooltip-arrow {
    background: linear-gradient(#45A358, #007D40);

}
#dekra-tooltip > .tooltip-arrow {
    background: linear-gradient(#45A358, #007D40);
    color: blueviolet;
    border-top: 5px solid #007D40;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #007D40;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #007D40;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #007D40;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #007D40;
}

textarea.form-control {
    height: auto;
    margin-bottom: 0px;
}
.textarea-margin-bottom {
    margin-bottom: 0px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background: linear-gradient(#45A358, #007D40);
}
.form-group {
   margin-bottom: 0px;
}
.col-form-label-sm {
    padding-top: 0px; 
    padding-bottom: 0px;
    font-size: 12px;
    line-height: 1.5;
}
.col-form-label-md {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
}
input {
    line-height: normal;
    /*margin-bottom: 4px;*/
}

    input[type="radio"],
    input[type="checkbox"] {
        /*margin: 1.5px 0 0 -20px;*/
        /* margin-top: 1px \9; */
        /* IE8-9 */
        line-height: normal;
    }
.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 1025px) {
    .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }
}
body {
    color: #007D40;
}
body {
    margin: 0;
}
body {
    font-family: Arial;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
.pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none;
}

    .pager li {
        display: inline;
    }

        .pager li > a,
        .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 15px;
        }

            .pager li > a:hover,
            .pager li > a:focus {
                text-decoration: none;
                background-color: #eee;
            }

    .pager .next > a,
    .pager .next > span {
        float: right;
    }

    .pager .previous > a,
    .pager .previous > span {
        float: left;
    }

    .pager .disabled > a,
    .pager .disabled > a:hover,
    .pager .disabled > a:focus,
    .pager .disabled > span {
        color: #999;
        cursor: not-allowed;
        background-color: #fff;
    }
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 2px;
}

    .pagination > li {
        display: inline;
    }

        .pagination > li > a,
        .pagination > li > span {
            position: relative;
            float: left;
            padding: 6px 12px;
            margin-left: -1px;
            line-height: 1.42857143;
            color: #007D40;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
        }

        .pagination > li:first-child > a,
        .pagination > li:first-child > span {
            margin-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .pagination > li:last-child > a,
        .pagination > li:last-child > span {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .pagination > li > a:hover,
        .pagination > li > span:hover,
        .pagination > li > a:focus,
        .pagination > li > span:focus {
            color: #007D40;
            background-color: #eee;
            border-color: #ddd;
        }

    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
        z-index: 2;
        color: #fff;
        cursor: default;
        background-color: #007D40;
        border-color: #007D40;
    }

    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > span:focus,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover,
    .pagination > .disabled > a:focus {
        color: #999;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
    }

.pagination-lg > li > a,
.pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
    color: #007D40;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

h1,
.h5 {
    font-size: 18px;
    font-weight: bold;
    color: #007D40;
}

label{
   /* font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;*/
    font-weight: bold;
    /*//color: black;
    //line-height: 19.7999992370605px;
    //color: #999;*/
}


label {
    /*display: inline-block;
    margin-bottom: 2.5px;*/
    color: #007D40;
    /*//font-weight: bold;
    //font-size:11px;*/
}

.table {
    width: 100%;
    /*margin-bottom: 20px;*/
}

    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #E5E5E5;
    }

    .table > thead > tr > th {
        vertical-align: middle;
        border-bottom: 2px solid #E5E5E5;
        /*height: 65px;*/
    }

    .table > caption + thead > tr:first-child > th,
    .table > colgroup + thead > tr:first-child > th,
    .table > thead:first-child > tr:first-child > th,
    .table > caption + thead > tr:first-child > td,
    .table > colgroup + thead > tr:first-child > td,
    .table > thead:first-child > tr:first-child > td {
        border-top: 0;
    }

    .table > tbody + tbody {
        border-top: 2px solid #E5E5E5;
    }

    .table .table {
        background-color: #fff;
    }

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
    padding: 5px;
}

.table-bordered {
    border: 1px solid #E5E5E5;
}

    .table-bordered > thead > tr > th {
        border: 1px solid transparent;
    }
    /*.table-bordered > thead > tr > th,*/
    .table-bordered > tbody > tr > th,
    .table-bordered > tfoot > tr > th,
    .table-bordered > thead > tr > td,
    .table-bordered > tbody > tr > td,
    .table-bordered > tfoot > tr > td {
        border: 1px solid #E5E5E5;
    }

    .table-bordered > thead > tr > th,
    .table-bordered > thead > tr > td {
        border-bottom-width: 2px;
    }

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    background-color: #f5f5f5;
}

table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
    background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th {
    background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
    background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th {
    background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
    background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th {
    background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
    background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th {
    background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
    background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th {
    background-color: #ebcccc;
}

@media (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }

        .table-responsive > .table {
            margin-bottom: 0;
        }

            .table-responsive > .table > thead > tr > th,
            .table-responsive > .table > tbody > tr > th,
            .table-responsive > .table > tfoot > tr > th,
            .table-responsive > .table > thead > tr > td,
            .table-responsive > .table > tbody > tr > td,
            .table-responsive > .table > tfoot > tr > td {
                white-space: nowrap;
            }

        .table-responsive > .table-bordered {
            border: 0;
        }

            .table-responsive > .table-bordered > thead > tr > th:first-child,
            .table-responsive > .table-bordered > tbody > tr > th:first-child,
            .table-responsive > .table-bordered > tfoot > tr > th:first-child,
            .table-responsive > .table-bordered > thead > tr > td:first-child,
            .table-responsive > .table-bordered > tbody > tr > td:first-child,
            .table-responsive > .table-bordered > tfoot > tr > td:first-child {
                border-left: 0;
            }

            .table-responsive > .table-bordered > thead > tr > th:last-child,
            .table-responsive > .table-bordered > tbody > tr > th:last-child,
            .table-responsive > .table-bordered > tfoot > tr > th:last-child,
            .table-responsive > .table-bordered > thead > tr > td:last-child,
            .table-responsive > .table-bordered > tbody > tr > td:last-child,
            .table-responsive > .table-bordered > tfoot > tr > td:last-child {
                border-right: 0;
            }

            .table-responsive > .table-bordered > tbody > tr:last-child > th,
            .table-responsive > .table-bordered > tfoot > tr:last-child > th,
            .table-responsive > .table-bordered > tbody > tr:last-child > td,
            .table-responsive > .table-bordered > tfoot > tr:last-child > td {
                border-bottom: 0;
            }
}

table {
    border-color: #D1D1D1;
}

table > thead {
    background-color: #007D40;
    color: white;
    /*height: 60px;*/
}

td {
    font-family: Arial;
    font-size: 12px;
    font-weight: normal;
    color: #555555;
}

th > a {
    font-family: Arial;
    font-weight: normal;
    color: white;
}

    th > a:hover {
        font-family: Arial;
        font-weight: normal;
        color: white;
    }
.btn-primary {
    color: #fff;
    background-color: #007d40;
    border-color: #007039;
    background: linear-gradient(#45A358, #007D40);
    /*background: rgba(69,163,88,1);
	background: -moz-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(69,163,88,1)), color-stop(20%, rgba(69,163,88,1)), color-stop(26%, rgba(69,163,88,1)), color-stop(55%, rgba(0,125,65,1)), color-stop(100%, rgba(0,125,65,1)));
	background: -webkit-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
	background: -o-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
	background: -ms-linear-gradient(top, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
	background: linear-gradient(to bottom, rgba(69,163,88,1) 0%, rgba(69,163,88,1) 20%, rgba(69,163,88,1) 26%, rgba(0,125,65,1) 55%, rgba(0,125,65,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45a358', endColorstr='#007d41', GradientType=0 );*/
}

    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:active,
    .btn-primary.active,
    .open .dropdown-toggle.btn-primary {
        color: #fff;
        background-color: #007039 !important;
        border-color: #00592d;
        box-shadow: 0 0 0 0.2rem #45A358!important;
    }

    .btn-primary:active,
    .btn-primary.active,
    .open .dropdown-toggle.btn-primary {
        background-image: none;
    }

    .btn-primary.disabled,
    .btn-primary[disabled],
    fieldset[disabled] .btn-primary,
    .btn-primary.disabled:hover,
    .btn-primary[disabled]:hover,
    fieldset[disabled] .btn-primary:hover,
    .btn-primary.disabled:focus,
    .btn-primary[disabled]:focus,
    fieldset[disabled] .btn-primary:focus,
    .btn-primary.disabled:active,
    .btn-primary[disabled]:active,
    fieldset[disabled] .btn-primary:active,
    .btn-primary.disabled.active,
    .btn-primary[disabled].active,
    fieldset[disabled] .btn-primary.active {
        background-color: #007039;
        border-color: #00592d;
        /*  background-color: #428bca;
  border-color: #357ebd;*/
    }

    .btn-primary .badge {
        color: #428bca;
        background-color: #fff;
    }


.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    
}

.nav > li {
    position: relative;
    display: block;
}

        .nav > li > a {
            position: relative;
            display: block;
            padding: 7.5px 7.5px;
            color: #666666;
        }

            .nav > li > a:hover,
            .nav > li > a:focus {
                text-decoration: none;
                background-color: transparent;
                color: black;
            }

        .nav > li.disabled > a {
            color: #999;
        }

            .nav > li.disabled > a:hover,
            .nav > li.disabled > a:focus {
                color: #999;
                text-decoration: none;
                cursor: not-allowed;
                background-color: transparent;
            }

    .nav .open > a,
    .nav .open > a:hover,
    .nav .open > a:focus {
        background-color: #eee;
        border-color: #428bca;
    }

    .nav .nav-divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }

    .nav > li > a > img {
        max-width: none;
    }

.nav-tabs {
    /*border-bottom: 1px solid #ddd;*/
    border-bottom: 1px solid #fff;
}

    .nav-tabs > li {
        float: left;
        margin-bottom: -1px;
    }

        .nav-tabs > li > a {
            margin-right: 2px;
            line-height: 1.42857143;
            /*border: 1px solid transparent;*/
            /*border: 1px solid #ddd;*/
            /*border: 1px solid #ddd;*/
            border-bottom: 1px solid #007D40;
            border-radius: 4px 4px 0 0;
        }

.nav-tabs > li > a:hover {
    border-color: #eee #eee #ddd;
    border-bottom: 1px solid #007D40;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-bottom: 1px solid #007D40;
}
        .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
            color: #555;
            cursor: default;
            background-color: #fff;
            /* border: 1px solid green;*/
            /*border: 1px solid #ddd;*/
            border: 1px solid #007D40;
            /*border: 1px solid #ddd;*/
            border-bottom-color: transparent;
        }

    .nav-tabs.nav-justified {
        width: 100%;
        border-bottom: 0;
    }

        .nav-tabs.nav-justified > li {
            float: none;
        }

            .nav-tabs.nav-justified > li > a {
                margin-bottom: 5px;
                text-align: center;
            }

        .nav-tabs.nav-justified > .dropdown .dropdown-menu {
            top: auto;
            left: auto;
        }

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li {
        display: table-cell;
        width: 1%;
    }

        .nav-tabs.nav-justified > li > a {
            margin-bottom: 0;
        }
}

.nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li > a {
        /*border: 1px solid #ddd;*/
        border-bottom: 1px solid #007D40;
        border-radius: 4px 4px 0 0;
    }

    .nav-tabs.nav-justified > .active > a,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:focus {
        border-bottom-color: #fff;
    }
}

.pull-right {
    float: right !important;
}

a {
    color: #007D40;
    text-decoration: none;
    font-size: 12px;
}
.a-advanced {
    color: #007D40 !important;
}
.navbar-collapse {
    max-height: 340px;
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
}
@media (min-width: 768px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }

        .navbar-collapse.collapse {
            display: block !important;
            height: auto !important;
            padding-bottom: 0;
            overflow: visible !important;
        }

        .navbar-collapse.in {
            overflow-y: visible;
        }

    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        padding-right: 0;
        padding-left: 0;
    }
}

.navbar-nav > li > a {
    line-height: 5px;
    font-size: 14px;
}
@media (max-width: 767px) {

    .navbar-nav {
        margin-top: 40px;
    }

        .navbar-nav > li > a {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .navbar-nav .open .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            box-shadow: none;
        }

            .navbar-nav .open .dropdown-menu > li > a,
            .navbar-nav .open .dropdown-menu .dropdown-header {
                padding: 5px 15px 5px 25px;
            }

            .navbar-nav .open .dropdown-menu > li > a {
                line-height: 20px;
            }

                .navbar-nav .open .dropdown-menu > li > a:hover,
                .navbar-nav .open .dropdown-menu > li > a:focus {
                    background-image: none;
                }
}
@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }

        .navbar-nav > li {
            float: left;
        }

            .navbar-nav > li > a {
                padding-top: 45px;
                padding-bottom: 35px;
            }

        .navbar-nav.navbar-right:last-child {
            margin-right: -15px;
        }
}

@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }
}

@media (min-width: 768px) {
    .navbar-left {
        float: left !important;
    }

    .navbar-right {
        float: right !important;
    }
}

.navbar {
    position: relative;
    min-height: 70px;
    /*margin-bottom: 20px;*/
    margin-bottom: 0px;
    border-bottom: 1px solid #D1D1D1;
}
.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block !important;
}

.navbar-nav {
    display: block;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

/* smal linje pvt*/
.card-border-dekra {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #EDF6F2;
    border-radius: 0.25rem;
}