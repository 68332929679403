/**************Start WeldSequence*****************/
.container-sequense {
    position: relative;
}
.sequenseImage1-sequense1 {
    position: absolute;
    top: 65px;
    left: 362px;
}
/***/
.sequenseImage2-sequense1 {
    position: absolute;
    top: 70px;
    left: 362px;
}
.sequenseImage2-sequense2 {
    position: absolute;
    top: 40px;
    left: 362px;
}
/***/
.sequenseImage3-sequense1 {
    position: absolute;
    top: 70px;
    left: 362px;
}

.sequenseImage3-sequense2 {
    position: absolute;
    top: 40px;
    left: 381px;
}

.sequenseImage3-sequense3 {
    position: absolute;
    top: 40px;
    left: 342px;
}
/***/
.sequenseImage4-sequense1 {
    position: absolute;
    top: 62px;
    left: 366px;
}

.sequenseImage4-sequense2 {
    position: absolute;
    top: 34px;
    left: 389px;
}

.sequenseImage4-sequense3 {
    position: absolute;
    top: 34px;
    left: 350px;
}
/***/
.sequenseImage5-sequense1 {
    position: absolute;
    top: 78px;
    left: 370px;
}

.sequenseImage5-sequense2 {
    position: absolute;
    top: 57px;
    left: 370px;
}

.sequenseImage5-sequense3 {
    position: absolute;
    top: 32px;
    left: 370px;
}
/***/
.sequenseImage6-sequense1 {
    position: absolute;
    top: 70px;
    left: 362px;
}

.sequenseImage6-sequense2 {
    position: absolute;
    top: 45px;
    left: 362px;
}
.sequenseImage6-sequense3 {
    position: absolute;
    top: 21px;
    left: 362px;
}
/***/
.sequenseImage7-sequense1 {
    position: absolute;
    top: 104px;
    left: 362px;
}
.sequenseImage7-sequense2 {
    position: absolute;
    top: 72px;
    left: 362px;
}
.sequenseImage7-sequense3 {
    position: absolute;
    top: 32px;
    left: 385px;
}
.sequenseImage7-sequense4 {
    position: absolute;
    top: 32px;
    left: 337px;
}
/***/
.sequenseImage8-sequense1 {
    position: absolute;
    top: 108px;
    left: 367px;
}

.sequenseImage8-sequense2 {
    position: absolute;
    top: 73px;
    left: 368px;
}

.sequenseImage8-sequense3 {
    position: absolute;
    top: 33px;
    left: 397px;
}
.sequenseImage8-sequense4 {
    position: absolute;
    top: 31px;
    left: 339px;
}
/***/
.sequenseImage9-sequense1 {
    position: absolute;
    top: 108px;
    left: 362px;
}

.sequenseImage9-sequense2 {
    position: absolute;
    top: 73px;
    left: 396px;
}

.sequenseImage9-sequense3 {
    position: absolute;
    top: 73px;
    left: 327px;
}

.sequenseImage9-sequense4 {
    position: absolute;
    top: 31px;
    left: 362px;
}
/***/
.sequenseImage10-sequense1 {
    position: absolute;
    top: 94px;
    left: 372px;
}

.sequenseImage10-sequense2 {
    position: absolute;
    top: 64px;
    left: 406px;
}

.sequenseImage10-sequense3 {
    position: absolute;
    top: 64px;
    left: 345px;
}

.sequenseImage10-sequense4 {
    position: absolute;
    top: 26px;
    left: 372px;
}
/***/
.sequenseImage11-sequense1 {
    position: absolute;
    top: 99px;
    left: 362px;
}

.sequenseImage11-sequense2 {
    position: absolute;
    top: 64px;
    left: 390px;
}

.sequenseImage11-sequense3 {
    position: absolute;
    top: 64px;
    left: 329px;
}

.sequenseImage11-sequense4 {
    position: absolute;
    top: 24px;
    left: 392px;
}
.sequenseImage11-sequense5 {
    position: absolute;
    top: 24px;
    left: 329px;
}
/***/
.sequenseImage12-sequense1 {
    position: absolute;
    top: 94px;
    left: 360px;
}

.sequenseImage12-sequense2 {
    position: absolute;
    top: 64px;
    left: 400px;
}

.sequenseImage12-sequense3 {
    position: absolute;
    top: 64px;
    left: 333px;
}

.sequenseImage12-sequense4 {
    position: absolute;
    top: 26px;
    left: 400px;
}
.sequenseImage12-sequense5 {
    position: absolute;
    top: 26px;
    left: 333px;
}
/***/
.sequenseImage13-sequense1 {
    position: absolute;
    top: 106px;
    left: 390px;
}

.sequenseImage13-sequense2 {
    position: absolute;
    top: 106px;
    left: 333px;
}

.sequenseImage13-sequense3 {
    position: absolute;
    top: 68px;
    left: 365px;
}

.sequenseImage13-sequense4 {
    position: absolute;
    top: 33px;
    left: 388px;
}

.sequenseImage13-sequense5 {
    position: absolute;
    top: 33px;
    left: 333px;
}
/***/
.sequenseImage14-sequense1 {
    position: absolute;
    top: 104px;
    left: 404px
}

.sequenseImage14-sequense2 {
    position: absolute;
    top: 104px;
    left: 350px;
}

.sequenseImage14-sequense3 {
    position: absolute;
    top: 68px;
    left: 375px;
}

.sequenseImage14-sequense4 {
    position: absolute;
    top: 26px;
    left: 404px;
}

.sequenseImage14-sequense5 {
    position: absolute;
    top: 26px;
    left: 350px;
}
/***/
.sequenseImage15-sequense1 {
    position: absolute;
    top: 104px;
    left: 360px;
}

.sequenseImage15-sequense2 {
    position: absolute;
    top: 72px;
    left: 385px;
}

.sequenseImage15-sequense3 {
    position: absolute;
    top: 72px;
    left: 335px;
}

.sequenseImage15-sequense4 {
    position: absolute;
    top: 30px;
    left: 406px;
}

.sequenseImage15-sequense5 {
    position: absolute;
    top: 30px;
    left: 358px;
}

.sequenseImage15-sequense6 {
    position: absolute;
    top: 30px;
    left: 310px;
}
/***/
.sequenseImage16-sequense1 {
    position: absolute;
    top: 96px;
    left: 358px;
}

.sequenseImage16-sequense2 {
    position: absolute;
    top: 64px;
    left: 385px;
}

.sequenseImage16-sequense3 {
    position: absolute;
    top: 64px;
    left: 332px;
}

.sequenseImage16-sequense4 {
    position: absolute;
    top: 30px;
    left: 405px;
}

.sequenseImage16-sequense5 {
    position: absolute;
    top: 30px;
    left: 355px;
}

.sequenseImage16-sequense6 {
    position: absolute;
    top: 30px;
    left: 308px;
}
/***/
.sequenseImage17-sequense1 {
    position: absolute;
    top: 104px;
    left: 352px;
}

.sequenseImage17-sequense2 {
    position: absolute;
    top: 74px;
    left: 380px;
}

.sequenseImage17-sequense3 {
    position: absolute;
    top: 74px;
    left: 315px;
}

.sequenseImage17-sequense4 {
    position: absolute;
    top: 30px;
    left: 400px;
}

.sequenseImage17-sequense5 {
    position: absolute;
    top: 30px;
    left: 348px;
}

.sequenseImage17-sequense6 {
    position: absolute;
    top: 30px;
    left: 300px;
}
/***/
.sequenseImage18-sequense1 {
    position: absolute;
    top: 103px;
    left: 384px;
}

.sequenseImage18-sequense2 {
    position: absolute;
    top: 103px;
    left: 340px;
}

.sequenseImage18-sequense3 {
    position: absolute;
    top: 74px;
    left: 363px;
}

.sequenseImage18-sequense4 {
    position: absolute;
    top: 47px;
    left: 363px;
}

.sequenseImage18-sequense5 {
    position: absolute;
    top: 26px;
    left: 385px;
}

.sequenseImage18-sequense6 {
    position: absolute;
    top: 26px;
    left: 340px;
}
/***/
.sequenseImage19-sequense1 {
    position: absolute;
    top: 120px;
    left: 362px;
}

.sequenseImage19-sequense2 {
    position: absolute;
    top: 94px;
    left: 388px;
}

.sequenseImage19-sequense3 {
    position: absolute;
    top: 94px;
    left: 343px;
}

.sequenseImage19-sequense4 {
    position: absolute;
    top: 60px;
    left: 384px;
}

.sequenseImage19-sequense5 {
    position: absolute;
    top: 60px;
    left: 340px;
}

.sequenseImage19-sequense6 {
    position: absolute;
    top: 26px;
    left: 404px;
}
.sequenseImage19-sequense7 {
    position: absolute;
    top: 24px;
    left: 365px;
}

.sequenseImage19-sequense8 {
    position: absolute;
    top: 26px;
    left: 321px;
}
/***/
.sequenseImage20-sequense1 {
    position: absolute;
    top: 106px;
    left: 372px;
}

.sequenseImage20-sequense2 {
    position: absolute;
    top: 80px;
    left: 396px;
}

.sequenseImage20-sequense3 {
    position: absolute;
    top: 80px;
    left: 353px;
}

.sequenseImage20-sequense4 {
    position: absolute;
    top: 52px;
    left: 404px;
}

.sequenseImage20-sequense5 {
    position: absolute;
    top: 52px;
    left: 368px;
}

.sequenseImage20-sequense6 {
    position: absolute;
    top: 52px;
    left: 324px;
}

.sequenseImage20-sequense7 {
    position: absolute;
    top: 22px;
    left: 412px;
}

.sequenseImage20-sequense8 {
    position: absolute;
    top: 22px;
    left: 370px;
}

.sequenseImage20-sequense9 {
    position: absolute;
    top: 22px;
    left: 325px;
}
/***/
.sequenseImage21-sequense1 {
    position: absolute;
    top: 116px;
    left: 360px;
}

.sequenseImage21-sequense2 {
    position: absolute;
    top: 88px;
    left: 382px;
}

.sequenseImage21-sequense3 {
    position: absolute;
    top: 88px;
    left: 338px;
}

.sequenseImage21-sequense4 {
    position: absolute;
    top: 56px;
    left: 396px;
}

.sequenseImage21-sequense5 {
    position: absolute;
    top: 56px;
    left: 348px;
}

.sequenseImage21-sequense6 {
    position: absolute;
    top: 56px;
    left: 310px;
}

.sequenseImage21-sequense7 {
    position: absolute;
    top: 22px;
    left: 418px;
}

.sequenseImage21-sequense8 {
    position: absolute;
    top: 22px;
    left: 370px;
}

.sequenseImage21-sequense9 {
    position: absolute;
    top: 22px;
    left: 325px;
}
.sequenseImage21-sequense10 {
    position: absolute;
    top: 22px;
    left: 280px;
}
/***/
.sequenseImage22-sequense1 {
    position: absolute;
    top: 122px;
    left: 362px;
}

.sequenseImage22-sequense2 {
    position: absolute;
    top: 105px;
    left: 386px;
}

.sequenseImage22-sequense3 {
    position: absolute;
    top: 105px;
    left: 350px;
}

.sequenseImage22-sequense4 {
    position: absolute;
    top: 78px;
    left: 386px;
}

.sequenseImage22-sequense5 {
    position: absolute;
    top: 78px;
    left: 344px;
}

.sequenseImage22-sequense6 {
    position: absolute;
    top: 52px;
    left: 406px;
}

.sequenseImage22-sequense7 {
    position: absolute;
    top: 52px;
    left: 364px;
}

.sequenseImage22-sequense8 {
    position: absolute;
    top: 52px;
    left: 330px;
}

.sequenseImage22-sequense9 {
    position: absolute;
    top: 22px;
    left: 415px;
}

.sequenseImage22-sequense10 {
    position: absolute;
    top: 22px;
    left: 376px;
}

.sequenseImage22-sequense11 {
    position: absolute;
    top: 22px;
    left: 348px;
}

.sequenseImage22-sequense12 {
    position: absolute;
    top: 22px;
    left: 312px;
}
/***/
.sequenseImage23-sequense1 {
    position: absolute;
    top: 124px;
    left: 368px;
}

.sequenseImage23-sequense2 {
    position: absolute;
    top: 100px;
    left: 388px;
}

.sequenseImage23-sequense3 {
    position: absolute;
    top: 100px;
    left: 346px;
}

.sequenseImage23-sequense4 {
    position: absolute;
    top: 76px;
    left: 404px;
}

.sequenseImage23-sequense5 {
    position: absolute;
    top: 76px;
    left: 362px;
}

.sequenseImage23-sequense6 {
    position: absolute;
    top: 80px;
    left: 314px;
}

.sequenseImage23-sequense7 {
    position: absolute;
    top: 48px;
    left: 412px;
}

.sequenseImage23-sequense8 {
    position: absolute;
    top: 48px;
    left: 372px;
}

.sequenseImage23-sequense9 {
    position: absolute;
    top: 50px;
    left: 330px;
}

.sequenseImage23-sequense10 {
    position: absolute;
    top: 50px;
    left: 292px;
}

.sequenseImage23-sequense11 {
    position: absolute;
    top: 22px;
    left: 425px;
}

.sequenseImage23-sequense12 {
    position: absolute;
    top: 22px;
    left: 385px;
}

.sequenseImage23-sequense13 {
    position: absolute;
    top: 22px;
    left: 348px;
}

.sequenseImage23-sequense14 {
    position: absolute;
    top: 22px;
    left: 315px;
}

.sequenseImage23-sequense15 {
    position: absolute;
    top: 22px;
    left: 275px;
}
/***/
.sequenseImage24-sequense1 {
    position: absolute;
    top: 106px;
    left: 382px;
}

.sequenseImage24-sequense2 {
    position: absolute;
    top: 106px;
    left: 340px;
}

.sequenseImage24-sequense3 {
    position: absolute;
    top: 82px;
    left: 361px;
}

.sequenseImage24-sequense4 {
    position: absolute;
    top: 62px;
    left: 361px;
}

.sequenseImage24-sequense5 {
    position: absolute;
    top: 40px;
    left: 361px;
}

.sequenseImage24-sequense6 {
    position: absolute;
    top: 18px;
    left: 382px;
}

.sequenseImage24-sequense7 {
    position: absolute;
    top: 18px;
    left: 342px;
}
/***/
.sequenseImage25-sequense1 {
    position: absolute;
    top: 122px;
    left: 387px;
}

.sequenseImage25-sequense2 {
    position: absolute;
    top: 122px;
    left: 352px;
}

.sequenseImage25-sequense3 {
    position: absolute;
    top: 122px;
    left: 316px;
}

.sequenseImage25-sequense4 {
    position: absolute;
    top: 90px;
    left: 372px;
}

.sequenseImage25-sequense5 {
    position: absolute;
    top: 90px;
    left: 334px;
}

.sequenseImage25-sequense6 {
    position: absolute;
    top: 70px;
    left: 350px;
}

.sequenseImage25-sequense7 {
    position: absolute;
    top: 48px;
    left: 370px;
}

.sequenseImage25-sequense8 {
    position: absolute;
    top: 48px;
    left: 332px;
}

.sequenseImage25-sequense9 {
    position: absolute;
    top: 16px;
    left: 390px;
}

.sequenseImage25-sequense10 {
    position: absolute;
    top: 16px;
    left: 348px;
}

.sequenseImage25-sequense11 {
    position: absolute;
    top: 16px;
    left: 314px;
}
/***/
.sequenseImage26-sequense1 {
    position: absolute;
    top: 112px;
    left: 410px;
}

.sequenseImage26-sequense2 {
    position: absolute;
    top: 112px;
    left: 382px;
}

.sequenseImage26-sequense3 {
    position: absolute;
    top: 112px;
    left: 350px;
}

.sequenseImage26-sequense4 {
    position: absolute;
    top: 112px;
    left: 316px;
}

.sequenseImage26-sequense5 {
    position: absolute;
    top: 86px;
    left: 364px;
}

.sequenseImage26-sequense6 {
    position: absolute;
    top: 62px;
    left: 364px;
}

.sequenseImage26-sequense7 {
    position: absolute;
    top: 44px;
    left: 364px;
}

.sequenseImage26-sequense8 {
    position: absolute;
    top: 16px;
    left: 411px;
}

.sequenseImage26-sequense9 {
    position: absolute;
    top: 16px;
    left: 389px;
}

.sequenseImage26-sequense10 {
    position: absolute;
    top: 16px;
    left: 355px;
}

.sequenseImage26-sequense11 {
    position: absolute;
    top: 16px;
    left: 318px;
}
/***/
.sequenseImage27-sequense1 {
    position: absolute;
    top: 122px;
    left: 400px;
}

.sequenseImage27-sequense2 {
    position: absolute;
    top: 122px;
    left: 368px;
}

.sequenseImage27-sequense3 {
    position: absolute;
    top: 122px;
    left: 336px;
}

.sequenseImage27-sequense4 {
    position: absolute;
    top: 122px;
    left: 306px;
}

.sequenseImage27-sequense5 {
    position: absolute;
    top: 90px;
    left: 384px;
}

.sequenseImage27-sequense6 {
    position: absolute;
    top: 90px;
    left: 342px;
}

.sequenseImage27-sequense7 {
    position: absolute;
    top: 70px;
    left: 360px;
}

.sequenseImage27-sequense8 {
    position: absolute;
    top: 46px;
    left: 374px;
}

.sequenseImage27-sequense9 {
    position: absolute;
    top: 46px;
    left: 332px;
}

.sequenseImage27-sequense10 {
    position: absolute;
    top: 20px;
    left: 405px;
}

.sequenseImage27-sequense11 {
    position: absolute;
    top: 20px;
    left: 370px;
}

.sequenseImage27-sequense12 {
    position: absolute;
    top: 22px;
    left: 338px;
}

.sequenseImage27-sequense13 {
    position: absolute;
    top: 22px;
    left: 305px;
}
/***/
.sequenseImage28-sequense1 {
    position: absolute;
    top: 88px;
    left: 382px;
}

.sequenseImage28-sequense2 {
    position: absolute;
    top: 88px;
    left: 346px;
}

.sequenseImage28-sequense3 {
    position: absolute;
    top: 58px;
    left: 356px;
}

.sequenseImage28-sequense4 {
    position: absolute;
    top: 26px;
    left: 382px;
}

.sequenseImage28-sequense5 {
    position: absolute;
    top: 26px;
    left: 345px;
}
/***/
.sequenseImage29-sequense1 {
    position: absolute;
    top: 102px;
    left: 412px;
}

.sequenseImage29-sequense2 {
    position: absolute;
    top: 102px;
    left: 380px;
}

.sequenseImage29-sequense3 {
    position: absolute;
    top: 105px;
    left: 350px;
}

.sequenseImage29-sequense4 {
    position: absolute;
    top: 76px;
    left: 356px;
}

.sequenseImage29-sequense5 {
    position: absolute;
    top: 56px;
    left: 358px;
}

.sequenseImage29-sequense6 {
    position: absolute;
    top: 24px;
    left: 418px;
}

.sequenseImage29-sequense7 {
    position: absolute;
    top: 24px;
    left: 380px;
}

.sequenseImage29-sequense8 {
    position: absolute;
    top: 24px;
    left: 350px;
}
/***/
.sequenseImage30-sequense1 {
    position: absolute;
    top: 70px;
    left: 390px;
}

.sequenseImage30-sequense2 {
    position: absolute;
    top: 70px;
    left: 340px;
}
/***/
.sequenseImage31-sequense1 {
    position: absolute;
    top: 80px;
    left: 398px;
}

.sequenseImage31-sequense2 {
    position: absolute;
    top: 80px;
    left: 370px;
}

.sequenseImage31-sequense3 {
    position: absolute;
    top: 80px;
    left: 340px;
}
/***/
.sequenseImage32-sequense1 {
    position: absolute;
    top: 76px;
    left: 394px;
}

.sequenseImage32-sequense2 {
    position: absolute;
    top: 76px;
    left: 366px;
}

.sequenseImage32-sequense3 {
    position: absolute;
    top: 82px;
    left: 340px;
}

.sequenseImage32-sequense4 {
    position: absolute;
    top: 50px;
    left: 386px;
}

.sequenseImage32-sequense5 {
    position: absolute;
    top: 52px;
    left: 340px;
}
/***/
.sequenseImage33-sequense1 {
    position: absolute;
    top: 72px;
    left: 386px;
}

.sequenseImage33-sequense2 {
    position: absolute;
    top: 76px;
    left: 352px;
}
/***/
.sequenseImage34-sequense1 {
    position: absolute;
    top: 80px;
    left: 400px;
}

.sequenseImage34-sequense2 {
    position: absolute;
    top: 80px;
    left: 360px;
}

.sequenseImage34-sequense3 {
    position: absolute;
    top: 48px;
    left: 388px;
}
/***/
.sequenseImage35-sequense1 {
    position: absolute;
    top: 90px;
    left: 400px;
}

.sequenseImage35-sequense2 {
    position: absolute;
    top: 90px;
    left: 366px;
}

.sequenseImage35-sequense3 {
    position: absolute;
    top: 92px;
    left: 340px;
}

.sequenseImage35-sequense4 {
    position: absolute;
    top: 60px;
    left: 396px;
}
/***/
.sequenseImage36-sequense1 {
    position: absolute;
    top: 48px;
    left: 342px;
}
/***/
.sequenseImage37-sequense1 {
    position: absolute;
    top: 68px;
    left: 348px;
}

.sequenseImage37-sequense2 {
    position: absolute;
    top: 28px;
    left: 356px;
}
/***/
.sequenseImage38-sequense1 {
    position: absolute;
    top: 74px;
    left: 336px;
}

.sequenseImage38-sequense2 {
    position: absolute;
    top: 46px;
    left: 339px;
}

.sequenseImage38-sequense3 {
    position: absolute;
    top: 16px;
    left: 346px;
}
/***/
.sequenseImage39-sequense1 {
    position: absolute;
    top: 70px;
    left: 340px;
}

.sequenseImage39-sequense2 {
    position: absolute;
    top: 50px;
    left: 344px;
}

.sequenseImage39-sequense3 {
    position: absolute;
    top: 20px;
    left: 370px;
}

.sequenseImage39-sequense4 {
    position: absolute;
    top: 20px;
    left: 336px;
}
/***/
.sequenseImage40-sequense1 {
    position: absolute;
    top: 78px;
    left: 336px;
}

.sequenseImage40-sequense2 {
    position: absolute;
    top: 55px;
    left: 356px;
}

.sequenseImage40-sequense3 {
    position: absolute;
    top: 52px;
    left: 324px;
}

.sequenseImage40-sequense4 {
    position: absolute;
    top: 25px;
    left: 362px;
}

.sequenseImage40-sequense5 {
    position: absolute;
    top: 25px;
    left: 330px;
}
/***/
.sequenseImage41-sequense1 {
    position: absolute;
    top: 76px;
    left: 340px;
}

.sequenseImage41-sequense2 {
    position: absolute;
    top: 50px;
    left: 366px;
}

.sequenseImage41-sequense3 {
    position: absolute;
    top: 50px;
    left: 336px;
}

.sequenseImage41-sequense4 {
    position: absolute;
    top: 22px;
    left: 396px;
}

.sequenseImage41-sequense5 {
    position: absolute;
    top: 78px;
    left: 344px;
}

.sequenseImage41-sequense5 {
    position: absolute;
    top: 22px;
    left: 364px;
}
.sequenseImage41-sequense6 {
    position: absolute;
    top: 22px;
    left: 334px;
}
/***/
.sequenseImage42-sequense1 {
    position: absolute;
    top: 66px;
    left: 372px;
    width: 20px;
}

.sequenseImage42-sequense2 {
    position: absolute;
    top: 74px;
    left: 366px;
    width: 5px;
}
/***/
.sequenseImage43-sequense1 {
    position: absolute;
    top: 46px;
    left: 367px;
    width: 24px;
}

.sequenseImage43-sequense2 {
    position: absolute;
    top: 72px;
    left: 389px;
    width: 24px;
}

.sequenseImage43-sequense3 {
    position: absolute;
    top: 70px;
    left: 377px;
    width: 10px;
}

.sequenseImage43-sequense4 {
    position: absolute;
    top: 78px;
    left: 366px;
    width: 12px;
}
/***/
.sequenseImage44-sequense1 {
    position: absolute;
    top: 36px;
    left: 363px;
    width: 24px;
}

.sequenseImage44-sequense2 {
    position: absolute;
    top: 54px;
    left: 377px;
    width: 24px;
}

.sequenseImage44-sequense3 {
    position: absolute;
    top: 76px;
    left: 392px;
    width: 24px;
}

.sequenseImage44-sequense4 {
    position: absolute;
    top: 71px;
    left: 375px;
    width: 14px;
}

.sequenseImage44-sequense5 {
    position: absolute;
    top: 78px;
    left: 368px;
}
/***/
.sequenseImage45-sequense1 {
    position: absolute;
    top: 54px;
    left: 364px;
    width: 24px;
}

.sequenseImage45-sequense2 {
    position: absolute;
    top: 71px;
    left: 382px;
    width: 24px;
}

.sequenseImage45-sequense3 {
    position: absolute;
    top: 74px;
    left: 372px;
}
/***/
.sequenseImage46-sequense1 {
    position: absolute;
    top: 43px;
    left: 364px;
    width: 24px;
}

.sequenseImage46-sequense2 {
    position: absolute;
    top: 70px;
    left: 392px;
    width: 24px;
}

.sequenseImage46-sequense3 {
    position: absolute;
    top: 64px;
    left: 369px;
    width: 18px;
}

.sequenseImage46-sequense4 {
    position: absolute;
    top: 82px;
    left: 384px;
    width: 18px;
}

.sequenseImage46-sequense5 {
    position: absolute;
    top: 81px;
    left: 374px;
}
/***/
.sequenseImage47-sequense1 {
    position: absolute;
    top: 38px;
    left: 362px;
    width: 24px;
}

.sequenseImage47-sequense2 {
    position: absolute;
    top: 58px;
    left: 379px;
    width: 24px;
}

.sequenseImage47-sequense3 {
    position: absolute;
    top: 78px;
    left: 396px;
    width: 24px;
}

.sequenseImage47-sequense4 {
    position: absolute;
    top: 59px;
    left: 365px;
    width: 18px;
}

.sequenseImage47-sequense5 {
    position: absolute;
    top: 76px;
    left: 380px;
    width: 18px;
}

.sequenseImage47-sequense6 {
    position: absolute;
    top: 78px;
    left: 365px;
    width: 18px;
}
/***/
.sequenseImage48-sequense1 {
    position: absolute;
    top: 104px;
    left: 368px;
    width: 24px;
}
/***/
.sequenseImage49-sequense1 {
    position: absolute;
    top: 90px;
    left: 366px;
    width: 24px;
}
/***/
.sequenseImage50-sequense1 {
    position: absolute;
    top: 76px;
    left: 371px;
    width: 24px;
}

.sequenseImage50-sequense2 {
    position: absolute;
    top: 86px;
    left: 366px;
    width: 18px;
    font-size: 13px;
}
/***/
.sequenseImage51-sequense1 {
    position: absolute;
    top: 76px;
    left: 368px;
    width: 24px;
}

.sequenseImage51-sequense2 {
    position: absolute;
    top: 108px;
    left: 398px;
    width: 24px;
}

.sequenseImage51-sequense3 {
    position: absolute;
    top: 104px;
    left: 379px;
    width: 18px;
}

.sequenseImage51-sequense4 {
    position: absolute;
    top: 112px;
    left: 366px;
    width: 18px;
}
/***/
.sequenseImage52-sequense1 {
    position: absolute;
    top: 111px;
    left: 372px;
    font-size: 13px;
}

.sequenseImage52-sequense2 {
    position: absolute;
    top: 100px;
    left: 374px;
    width: 18px;
}

.sequenseImage52-sequense3 {
    position: absolute;
    top: 108px;
    left: 401px;
    width: 24px;
}

.sequenseImage52-sequense4 {
    position: absolute;
    top: 86px;
    left: 383px;
    width: 23px;
}

.sequenseImage52-sequense5 {
    position: absolute;
    top: 65px;
    left: 364px;
    width: 24px;
}
/***/
.sequenseImage53-sequense1 {
    position: absolute;
    top: 109px;
    left: 370px;
    width: 18px;
}

.sequenseImage53-sequense2 {
    position: absolute;
    top: 104px;
    left: 387px;
    width: 24px;
}

.sequenseImage53-sequense3 {
    position: absolute;
    top: 86px;
    left: 368px;
    width: 24px;
}
/***/
.sequenseImage54-sequense1 {
    position: absolute;
    top: 117px;
    left: 376px;
    font-size: 13px;
    width: 14px;
}

.sequenseImage54-sequense2 {
    position: absolute;
    top: 113px;
    left: 395px;
    width: 14px;
}

.sequenseImage54-sequense3 {
    position: absolute;
    top: 100px;
    left: 376px;
    width: 14px;
}

.sequenseImage54-sequense4 {
    position: absolute;
    top: 110px;
    left: 409px;
    width: 24px;
}

.sequenseImage54-sequense5 {
    position: absolute;
    top: 78px;
    left: 198px;
    width: 24pc;
}

/***/
.sequenseImage55-sequense1 {
    position: absolute;
    top: 112px;
    left: 370px;
    width: 18px;
}

.sequenseImage55-sequense2 {
    position: absolute;
    top: 88px;
    left: 372px;
    width: 14px;
}

.sequenseImage55-sequense3 {
    position: absolute;
    top: 110px;
    left: 390px;
    width: 14px;
}

.sequenseImage55-sequense4 {
    position: absolute;
    top: 112px;
    left: 408px;
    width: 24px;
}

.sequenseImage55-sequense5 {
    position: absolute;
    top: 88px;
    left: 389px;
    width: 24px;
}

.sequenseImage55-sequense6 {
    position: absolute;
    top: 62px;
    left: 368px;
    width: 24px;
}
/***/
.sequenseImage56-sequense1 {
    position: absolute;
    top: 72px;
    left: 318px;
    width: 24px;
}
/***/
.sequenseImage57-sequense1 {
    position: absolute;
    top: 85px;
    left: 318px;
    width: 18px;
}

.sequenseImage57-sequense2 {
    position: absolute;
    top: 66px;
    left: 320px;
    width: 24px;
}
/***/
.sequenseImage58-sequense1 {
    position: absolute;
    top: 87px;
    left: 320px;
    width: 14px;
}

.sequenseImage58-sequense2 {
    position: absolute;
    top: 69px;
    left: 317px;
    width: 24px;
}

.sequenseImage58-sequense3 {
    position: absolute;
    top: 73px;
    left: 358px;
    width: 24px;
    ;
}

.sequenseImage58-sequense4 {
    position: absolute;
    top: 52px;
    left: 330px;
    width: 24px;
}

.sequenseImage58-sequense5 {
    position: absolute;
    top: 32px;
    left: 306px;
    width: 24px;
}
/***/
.sequenseImage59-sequense1 {
    position: absolute;
    top: 78px;
    left: 318px;
    width: 14px;
}

.sequenseImage59-sequense2 {
    position: absolute;
    top: 61px;
    left: 321px;
    width: 18px;
}

.sequenseImage59-sequense3 {
    position: absolute;
    top: 64px;
    left: 342px;
    width: 24px;
}

.sequenseImage59-sequense4 {
    position: absolute;
    top: 34px;
    left: 310px;
    width: 24px;
}
/***/
.sequenseImage60-sequense1 {
    position: absolute;
    top: 80px;
    left: 310px;
    width: 14px;
}

.sequenseImage60-sequense2 {
    position: absolute;
    top: 76px;
    left: 330px;
    width: 24px;
}

.sequenseImage60-sequense3 {
    position: absolute;
    top: 55px;
    left: 308px;
    width: 24px;
}
/***/
.sequenseImage61-sequense1 {
    position: absolute;
    top: 76px;
    left: 308px;
    width: 18px;
}

.sequenseImage61-sequense2 {
    position: absolute;
    top: 74px;
    left: 332px;
    width: 18px;
}

.sequenseImage61-sequense3 {
    position: absolute;
    top: 58px;
    left: 314px;
    width: 18px;
}

.sequenseImage61-sequense4 {
    position: absolute;
    top: 67px;
    left: 355px;
    width: 24px;
}

.sequenseImage61-sequense5 {
    position: absolute;
    top: 35px;
    left: 314px;
    width: 24px;
}
/***/
.sequenseImage62-sequense1 {
    position: absolute;
    top: 76px;
    left: 306px;
    width: 18px;
}

.sequenseImage62-sequense2 {
    position: absolute;
    top: 76px;
    left: 330px;
    width: 18px;
}

.sequenseImage62-sequense3 {
    position: absolute;
    top: 56px;
    left: 309px;
    width: 18px;
}

.sequenseImage62-sequense4 {
    position: absolute;
    top: 70px;
    left: 354px;
    width: 24px;
}

.sequenseImage62-sequense5 {
    position: absolute;
    top: 54px;
    left: 330px;
    width: 24px;
}

.sequenseImage62-sequense6 {
    position: absolute;
    top: 30px;
    left: 306px;
    width: 24px;
}
/***/
.sequenseImage63-sequense1 {
    position: absolute;
    top: 88px;
    left: 392px;
    width: 24px;
}
/***/
.sequenseImage64-sequense1 {
    position: absolute;
    top: 98px;
    left: 374px;
    width: 24px;
}

.sequenseImage64-sequense2 {
    position: absolute;
    top: 86px;
    left: 402px;
    width: 24px;
}
/***/
.sequenseImage65-sequense1 {
    position: absolute;
    top: 92px;
    left: 364px;
    width: 18px;
}

.sequenseImage65-sequense2 {
    position: absolute;
    top: 84px;
    left: 384px;
    width: 24px;
}

.sequenseImage65-sequense3 {
    position: absolute;
    top: 92px;
    left: 414px;
    width: 24px;
}

.sequenseImage65-sequense4 {
    position: absolute;
    top: 52px;
    left: 388px;
    width: 24px;
}
/***/
.sequenseImage66-sequense1 {
    position: absolute;
    top: 90px;
    left: 372px;
    width: 14px;
}

.sequenseImage66-sequense2 {
    position: absolute;
    top: 90px;
    left: 389px;
    width: 18px;
}

.sequenseImage66-sequense3 {
    position: absolute;
    top: 102px;
    left: 416px;
    width: 24px;
}

.sequenseImage66-sequense4 {
    position: absolute;
    top: 78px;
    left: 399px;
    width: 24px;
}

.sequenseImage66-sequense5 {
    position: absolute;
    top: 58px;
    left: 385px;
    width: 24px;
}
/***/
.sequenseImage67-sequense1 {
    position: absolute;
    top: 102px;
    left: 378px;
    width: 18px;
}

.sequenseImage67-sequense2 {
    position: absolute;
    top: 107px;
    left: 406px;
    width: 24px;
}

.sequenseImage67-sequense3 {
    position: absolute;
    top: 80px;
    left: 392px;
    width: 24px;
}
/***/
.sequenseImage68-sequense1 {
    position: absolute;
    top: 110px;
    left: 376px;
    width: 14px;
}

.sequenseImage68-sequense2 {
    position: absolute;
    top: 113px;
    left: 397px;
    width: 18px;
}

.sequenseImage68-sequense3 {
    position: absolute;
    top: 88px;
    left: 382px;
    width: 18px;
}

.sequenseImage68-sequense4 {
    position: absolute;
    top: 99px;
    left: 414px;
    width: 24px;
}

.sequenseImage68-sequense5 {
    position: absolute;
    top: 64px;
    left: 392px;
    width: 24px;
}
/***/
.sequenseImage69-sequense1 {
    position: absolute;
    top: 103px;
    left: 383px;
    width: 14px;
}

.sequenseImage69-sequense2 {
    position: absolute;
    top: 96px;
    left: 401px;
    width: 14px;
}

.sequenseImage69-sequense3 {
    position: absolute;
    top: 80px;
    left: 382px;
    width: 14px;
}

.sequenseImage69-sequense4 {
    position: absolute;
    top: 100px;
    left: 420px;
    width: 24px;
}

.sequenseImage69-sequense5 {
    position: absolute;
    top: 50px;
    left: 380px;
    width: 24px;
}

.sequenseImage69-sequense6 {
    position: absolute;
    top: 70px;
    left: 400px;
    width: 24px;
}
/***/
.sequenseImage70-sequense1 {
    position: absolute;
    top: 57px;
    left: 364px;
    width: 14px;
}

.sequenseImage70-sequense2 {
    position: absolute;
    top: 74px;
    left: 368px;
    width: 18px;
}

.sequenseImage70-sequense3 {
    position: absolute;
    top: 112px;
    left: 354px;
    width: 24px;
}

.sequenseImage70-sequense4 {
    position: absolute;
    top: 92px;
    left: 376px;
    width: 24px;
}

.sequenseImage70-sequense5 {
    position: absolute;
    top: 66px;
    left: 406px;
    width: 24px;
}
/***/
.sequenseImage71-sequense1 {
    position: absolute;
    top: 66px;
    left: 364px;
    width: 24px;
}

.sequenseImage71-sequense2 {
    position: absolute;
    top: 96px;
    left: 364px;
    width: 24px
}

.sequenseImage71-sequense3 {
    position: absolute;
    top: 78px;
    left: 389px;
    width: 24px;
}
/***/
.sequenseImage72-sequense1 {
    position: absolute;
    top: 69px;
    left: 362px;
    width: 14px;
}

.sequenseImage72-sequense2 {
    position: absolute;
    top: 92px;
    left: 360px;
    width: 18px;
}

.sequenseImage72-sequense3 {
    position: absolute;
    top: 71px;
    left: 380px;
    width: 18px;
}

.sequenseImage72-sequense4 {
    position: absolute;
    top: 74px;
    left: 403px;
    width: 24px;
}

.sequenseImage72-sequense5 {
    position: absolute;
    top: 110px;
    left: 368px;
    width: 24px;
}
/***/
.sequenseImage73-sequense1 {
    position: absolute;
    top: 73px;
    left: 371px;
    width: 14px;
}

.sequenseImage73-sequense2 {
    position: absolute;
    top: 72px;
    left: 396px;
    width: 14px;
}

.sequenseImage73-sequense3 {
    position: absolute;
    top: 89px;
    left: 377px;
    width: 14px;
}

.sequenseImage73-sequense4 {
    position: absolute;
    top: 70px;
    left: 418px;
    width: 24px;
}

.sequenseImage73-sequense5 {
    position: absolute;
    top: 94px;
    left: 394px;
    width: 24px;
}

.sequenseImage73-sequense6 {
    position: absolute;
    top: 118px;
    left: 364px;
    width: 24px;
}
/***/
.sequenseImage74-sequense1 {
    position: absolute;
    top: 24px;
    left: 362px;
    width: 24px;
}
/***/
.sequenseImage75-sequense1 {
    position: absolute;
    top: 17px;
    left: 360px;
    width: 14px;
}

.sequenseImage75-sequense2 {
    position: absolute;
    top: 31px;
    left: 366px;
    width: 24px;
}
/***/
.sequenseImage76-sequense1 {
    position: absolute;
    top: 16px;
    left: 360px;
    width: 14px;
}

.sequenseImage76-sequense2 {
    position: absolute;
    top: 20px;
    left: 373px;
    width: 18px;
}

.sequenseImage76-sequense3 {
    position: absolute;
    top: 18px;
    left: 396px;
    width: 24px;
}

.sequenseImage76-sequense4 {
    position: absolute;
    top: 48px;
    left: 366px;
    width: 24px;
}
/***/
.sequenseImage77-sequense1 {
    position: absolute;
    top: 11px;
    left: 360px;
    width: 14px;
    font-size: 13px;
}

.sequenseImage77-sequense2 {
    position: absolute;
    top: 20px;
    left: 374px;
    width: 14px;
}

.sequenseImage77-sequense3 {
    position: absolute;
    top: 14px;
    left: 392px;
    width: 18px;
}

.sequenseImage77-sequense4 {
    position: absolute;
    top: 43px;
    left: 366px;
    width: 14px;
}

.sequenseImage77-sequense5 {
    position: absolute;
    top: 18px;
    left: 414px;
    width: 24px;
}

.sequenseImage77-sequense6 {
    position: absolute;
    top: 42px;
    left: 390px;
    width: 24px;
}

.sequenseImage77-sequense7 {
    position: absolute;
    top: 72px;
    left: 364px;
    width: 24px;
}
/***/
.sequenseImage78-sequense1 {
    position: absolute;
    top: 22px;
    left: 360px;
    width: 18px;
}

.sequenseImage78-sequense2 {
    position: absolute;
    top: 47px;
    left: 363px;
    width: 24px;
}

.sequenseImage78-sequense3 {
    position: absolute;
    top: 26px;
    left: 380px;
    width: 24px;
}
/***/
.sequenseImage79-sequense1 {
    position: absolute;
    top: 16px;
    left: 363px;
    width: 18px;
}

.sequenseImage79-sequense2 {
    position: absolute;
    top: 16px;
    left: 385px;
    width: 18px;
}

.sequenseImage79-sequense3 {
    position: absolute;
    top: 37px;
    left: 366px;
    width: 18px;
}

.sequenseImage79-sequense4 {
    position: absolute;
    top: 24px;
    left: 402px;
    width: 24px;
}

.sequenseImage79-sequense5 {
    position: absolute;
    top: 50px;
    left: 376px;
    width: 24px;
}

/***/
.sequenseImage80-sequense1 {
    position: absolute;
    top: 20px;
    left: 366px;
    width: 14px;
}

.sequenseImage80-sequense2 {
    position: absolute;
    top: 40px;
    left: 370px;
    width: 14px;
}

.sequenseImage80-sequense3 {
    position: absolute;
    top: 20px;
    left: 395px;
    width: 14px;
}

.sequenseImage80-sequense4 {
    position: absolute;
    top: 62px;
    left: 362px;
    width: 24px;
}

.sequenseImage80-sequense5 {
    position: absolute;
    top: 42px;
    left: 384px;
    width: 24px;
}

.sequenseImage80-sequense6 {
    position: absolute;
    top: 22px;
    left: 409px;
    width: 24px;
}
/***/
.sequenseImage81-sequense1 {
    position: absolute;
    top: 66px;
    left: 372px;
    width: 24px;
}
/***/
.sequenseImage82-sequense1 {
    position: absolute;
    top: 69px;
    left: 366px;
    width: 24px;
}

.sequenseImage82-sequense2 {
    position: absolute;
    top: 82px;
    left: 388px;
    width: 24px;
}
/***/
.sequenseImage83-sequense1 {
    position: absolute;
    top: 70px;
    left: 364px;
    width: 24px;
}

.sequenseImage83-sequense2 {
    position: absolute;
    top: 80px;
    left: 388px;
    width: 18px;
}

.sequenseImage83-sequense3 {
    position: absolute;
    top: 110px;
    left: 386px;
    width: 24px;
}

.sequenseImage83-sequense4 {
    position: absolute;
    top: 78px;
    left: 409px;
    width: 24px;
}
/***/
.sequenseImage84-sequense1 {
    position: absolute;
    top: 66px;
    left: 366px;
    width: 18px;
}

.sequenseImage84-sequense2 {
    position: absolute;
    top: 72px;
    left: 387px;
    width: 14px;
}

.sequenseImage84-sequense3 {
    position: absolute;
    top: 108px;
    left: 378px;
    width: 24px;
}

.sequenseImage84-sequense4 {
    position: absolute;
    top: 88px;
    left: 399px;
    width: 24px;
}

.sequenseImage84-sequense5 {
    position: absolute;
    top: 63px;
    left: 408px;
    width: 24px;
}
/***/
.sequenseImage85-sequense1 {
    position: absolute;
    top: 71px;
    left: 362px;
    width: 18px;
}

.sequenseImage85-sequense2 {
    position: absolute;
    top: 90px;
    left: 374px;
    width: 14px;
}

.sequenseImage85-sequense3 {
    position: absolute;
    top: 64px;
    left: 390px;
    width: 14px;
}

.sequenseImage85-sequense4 {
    position: absolute;
    top: 108px;
    left: 388px;
    width: 24px;
}

.sequenseImage85-sequense5 {
    position: absolute;
    top: 78px;
    left: 408px;
    width: 24px;
}
/***/
.sequenseImage86-sequense1 {
    position: absolute;
    top: 62px;
    left: 378px;
    width: 14px;
}

.sequenseImage86-sequense2 {
    position: absolute;
    top: 90px;
    left: 379px;
    width: 24px;
}

.sequenseImage86-sequense3 {
    position: absolute;
    top: 74px;
    left: 406px;
    width: 24px;
}
/***/
.sequenseImage87-sequense1 {
    position: absolute;
    top: 76px;
    left: 366px;
    width: 14px;
}

.sequenseImage87-sequense2 {
    position: absolute;
    top: 94px;
    left: 378px;
    width: 18px;
}

.sequenseImage87-sequense3 {
    position: absolute;
    top: 68px;
    left: 392px;
    width: 18px;
}

.sequenseImage87-sequense4 {
    position: absolute;
    top: 122px;
    left: 384px;
    width: 18px;
}

.sequenseImage87-sequense5 {
    position: absolute;
    top: 100px;
    left: 396px;
    width: 24px;
}

.sequenseImage87-sequense6 {
    position: absolute;
    top: 72px;
    left: 412px;
    width: 24px;
}
/***/
.sequenseImage88-sequense1 {
    position: absolute;
    top: 36px;
    left: 360px;
    width: 24px;
}

/***/
.sequenseImage89-sequense1 {
    position: absolute;
    top: 28px;
    left: 356px;
    width: 14px;
}

.sequenseImage89-sequense2 {
    position: absolute;
    top: 40px;
    left: 366px;
    width: 24px;
}
/***/
.sequenseImage90-sequense1 {
    position: absolute;
    top: 28px;
    left: 354px;
    width: 18px;
}

.sequenseImage90-sequense2 {
    position: absolute;
    top: 40px;
    left: 368px;
    width: 18px;
}

.sequenseImage90-sequense3 {
    position: absolute;
    top: 76px;
    left: 356px;
    width: 24px;
}

.sequenseImage90-sequense4 {
    position: absolute;
    top: 52px;
    left: 380px;
    width: 24px;
}

.sequenseImage90-sequense5 {
    position: absolute;
    top: 24px;
    left: 400px;
    width: 24px;
}
/***/
.sequenseImage91-sequense1 {
    position: absolute;
    top: 28px;
    left: 356px;
    width: 18px;
}

.sequenseImage91-sequense2 {
    position: absolute;
    top: 54px;
    left: 362px;
    width: 24px;
}

.sequenseImage91-sequense3 {
    position: absolute;
    top: 26px;
    left: 382px;
    width: 24px;
}
/***/
.sequenseImage92-sequense1 {
    position: absolute;
    top: 29px;
    left: 354px;
    width: 24px;
}

.sequenseImage92-sequense2 {
    position: absolute;
    top: 51px;
    left: 361px;
    width: 14px;
}

.sequenseImage92-sequense3 {
    position: absolute;
    top: 21px;
    left: 385px;
    width: 14px;
}

.sequenseImage92-sequense4 {
    position: absolute;
    top: 80px;
    left: 358px;
    width: 24px;
}

.sequenseImage92-sequense5 {
    position: absolute;
    top: 48px;
    left: 380px;
    width: 24px;
}

.sequenseImage92-sequense6 {
    position: absolute;
    top: 23px;
    left: 408px;
    width: 24px;
}
/***/
.sequenseImage93-sequense1 {
    position: absolute;
    top: 29px;
    left: 356px;
    width: 24px;
}

.sequenseImage93-sequense2 {
    position: absolute;
    top: 50px;
    left: 366px;
}

.sequenseImage93-sequense3 {
    position: absolute;
    top: 24px;
    left: 378px;
    width: 18px;
}

.sequenseImage93-sequense4 {
    position: absolute;
    top: 72px;
    left: 366px;
    width: 24px;
}

.sequenseImage93-sequense5 {
    position: absolute;
    top: 30px;
    left: 400px;
    width: 24px;
}
/***/
.sequenseImage94-sequense1 {
    position: absolute;
    top: 70px;
    left: 380px;
    width: 24px;
}
/***/
.sequenseImage95-sequense1 {
    position: absolute;
    top: 66px;
    left: 356px;
    width: 24px;
}

.sequenseImage95-sequense2 {
    position: absolute;
    top: 80px;
    left: 386px;
    width: 24px;
}
/***/
.sequenseImage96-sequense1 {
    position: absolute;
    top: 66px;
    left: 354px;
    width: 18px;
}

.sequenseImage96-sequense2 {
    position: absolute;
    top: 68px;
    left: 378px;
    width: 14px;
}

.sequenseImage96-sequense3 {
    position: absolute;
    top: 90px;
    left: 390px;
    width: 24px;
}

.sequenseImage96-sequense4 {
    position: absolute;
    top: 62px;
    left: 400px;
    width: 24px;
}
/***/
.sequenseImage97-sequense1 {
    position: absolute;
    top: 66px;
    left: 352px;
    width: 18px;
}

.sequenseImage97-sequense2 {
    position: absolute;
    top: 69px;
    left: 374px;
    width: 18px;
}

.sequenseImage97-sequense3 {
    position: absolute;
    top: 94px;
    left: 382px;
    width: 18px;
}

.sequenseImage97-sequense4 {
    position: absolute;
    top: 80px;
    left: 396px;
    width: 24px;
}

.sequenseImage97-sequense5 {
    position: absolute;
    top: 58px;
    left: 394px;
    width: 18px;
    font-size: 13px;
}
/***/
.sequenseImage98-sequense1 {
    position: absolute;
    top: 66px;
    left: 360px;
    width: 24px;
}

.sequenseImage98-sequense2 {
    position: absolute;
    top: 86px;
    left: 386px;
    width: 24px;
}

.sequenseImage98-sequense3 {
    position: absolute;
    top: 58px;
    left: 394px;
    width: 24px;
}
/***/
.sequenseImage99-sequense1 {
    position: absolute;
    top: 64px;
    left: 356px;
    width: 18px;
}

.sequenseImage99-sequense2 {
    position: absolute;
    top: 76px;
    left: 372px;
    width: 14px;
    font-size: 13px;
}

.sequenseImage99-sequense3 {
    position: absolute;
    top: 55px;
    left: 384px;
    width: 14px;
}

.sequenseImage99-sequense4 {
    position: absolute;
    top: 89px;
    left: 386px;
    width: 24px;
}

.sequenseImage99-sequense5 {
    position: absolute;
    top: 63px;
    left: 398px;
    width: 24px;
}
/***/
.sequenseImage100-sequense1 {
    position: absolute;
    top: 64px;
    left: 356px;
    width: 14px;
}

.sequenseImage100-sequense2 {
    position: absolute;
    top: 82px;
    left: 364px;
    width: 18px;
}

.sequenseImage100-sequense3 {
    position: absolute;
    top: 60px;
    left: 384px;
    width: 18px;
}

.sequenseImage100-sequense4 {
    position: absolute;
    top: 96px;
    left: 379px;
    width: 24px;
}

.sequenseImage100-sequense5 {
    position: absolute;
    top: 82px;
    left: 399px;
    width: 24px;
}

.sequenseImage100-sequense6 {
    position: absolute;
    top: 59px;
    left: 404px;
    width: 24px;
    font-size: 13px;
}
/***/
.sequenseImage101-sequense1 {
    position: absolute;
    top: 56px;
    left: 330px;
    width: 24px;
}
/***/
.sequenseImage102-sequense1 {
    position: absolute;
    top: 40px;
    left: 330px;
    width: 24px;
}

.sequenseImage102-sequense2 {
    position: absolute;
    top: 78px;
    left: 326px;
    width: 24px;
}
/***/
.sequenseImage103-sequense1 {
    position: absolute;
    top: 26px;
    left: 310px;
    width: 24px;
}

.sequenseImage103-sequense2 {
    position: absolute;
    top: 30px;
    left: 346px;
    width: 24px;
}

.sequenseImage103-sequense3 {
    position: absolute;
    top: 54px;
    left: 326px;
    width: 24px;
}

.sequenseImage103-sequense4 {
    position: absolute;
    top: 75px;
    left: 322px;
    width: 24px;
}
/***/
.sequenseImage104-sequense1 {
    position: absolute;
    top: 76px;
    left: 324px;
    width: 24px;
}

.sequenseImage104-sequense2 {
    position: absolute;
    top: 50px;
    left: 336px;
    width: 24px;
}

.sequenseImage104-sequense3 {
    position: absolute;
    top: 38px;
    left: 366px;
    width: 24px;
}

.sequenseImage104-sequense4 {
    position: absolute;
    top: 22px;
    left: 344px;
    width: 24px;
}

.sequenseImage104-sequense5 {
    position: absolute;
    top: 18px;
    left: 318px;
    width: 18px;
}
/***/
.sequenseImage105-sequense1 {
    position: absolute;
    top: 36px;
    left: 314px;
    width: 24px;
}

.sequenseImage105-sequense2 {
    position: absolute;
    top: 72px;
    left: 326px;
    width: 24px;
}

.sequenseImage105-sequense3 {
    position: absolute;
    top: 38px;
    left: 348px;
    width: 24px;
}
/***/
.sequenseImage106-sequense1 {
    position: absolute;
    top: 22px;
    left: 320px;
    width: 24px;
}

.sequenseImage106-sequense2 {
    position: absolute;
    top: 50px;
    left: 310px;
    width: 24px;
}

.sequenseImage106-sequense3 {
    position: absolute;
    top: 80px;
    left: 322px;
    width: 24px;
}

.sequenseImage106-sequense4 {
    position: absolute;
    top: 58px;
    left: 346px;
    width: 24px;
}

.sequenseImage106-sequense5 {
    position: absolute;
    top: 30px;
    left: 356px;
    width: 24px;
}
/***/
.sequenseImage107-sequense1 {
    position: absolute;
    top: 26px;
    left: 314px;
    width: 18px;
}

.sequenseImage107-sequense2 {
    position: absolute;
    top: 52px;
    left: 313px;
    width: 14px;
}

.sequenseImage107-sequense3 {
    position: absolute;
    top: 82px;
    left: 316px;
    width: 24px;
}

.sequenseImage107-sequense4 {
    position: absolute;
    top: 54px;
    left: 332px;
    width: 24px;
}

.sequenseImage107-sequense5 {
    position: absolute;
    top: 40px;
    left: 364px;
    width: 18px;
}

.sequenseImage107-sequense6 {
    position: absolute;
    top: 26px;
    left: 338px;
    width: 24px;
}
/***/
.sequenseImage108-sequense1 {
    position: absolute;
    top: 82px;
    left: 370px;
    width: 24px;
}
/***/
.sequenseImage109-sequense1 {
    position: absolute;
    top: 62px;
    left: 366px;
    width: 14px;
    font-size: 13px;
}

.sequenseImage109-sequense2 {
    position: absolute;
    top: 84px;
    left: 372px;
    width: 24px;
}
/***/
.sequenseImage110-sequense1 {
    position: absolute;
    top: 61px;
    left: 370px;
    width: 14px;
    font-size: 13px;
}

.sequenseImage110-sequense2 {
    position: absolute;
    top: 78px;
    left: 373px;
    width: 18px;
}

.sequenseImage110-sequense3 {
    position: absolute;
    top: 104px;
    left: 365px;
    width: 24px;
}

.sequenseImage110-sequense4 {
    position: absolute;
    top: 76px;
    left: 402px;
    width: 24px;
}

.centerStrang4 {
    position: absolute;
    top: 25px;
    left: 371px;
}
.centerStrang3 {
    position: absolute;
    top: 64px;
    left: 338px;
}
.centerStrang2 {
    position: absolute;
    top: 62px;
    left: 410px;
}
.centerStrang1 {
    position: absolute;
    top: 99px;
    left: 369px;
}
/**************End WeldSequence*****************/
