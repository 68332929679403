a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 12px; }

@media (min-width: 1024px) {
    html { font-size: 14px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.dekra-container-head {
    align-items: flex-end !important;
}

.topText {
    color: red;
    margin-right: 10px;
    height: 28px;
    line-height: 28px;
}

/*.dekra-nav-tabs > li > a {
    font-size: 14px;*/
    /*background: rgba(226,226,226,1);
background: -moz-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(226,226,226,1)), color-stop(50%, rgba(219,219,219,1)), color-stop(51%, rgba(209,209,209,1)), color-stop(100%, rgba(254,254,254,1)));
background: -webkit-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -o-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -ms-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: linear-gradient(to bottom, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0 );*/
    /*background: linear-gradient(#F1F1F1, #D1D1D1);
    border-bottom: 1px solid transparent;
}*/

/*.pagination > .active > a:focus,
.navbar-light .navbar-nav .nav-item .nav-link.active {
    z-index: 2;
    color: #fff !important;
    cursor: default;
    background-color: #007D40;
    border-color: #007D40;
}
.navbar-nav > li.active > a, .navbar-nav > li.active > a:hover, .navbar-nav > li.active > a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;*/
    /* border: 1px solid green; */
    /* border: 1px solid #ddd; */
    /*border: 1px solid #007D40;*/
    /* border: 1px solid #ddd; */
    /*border-bottom-color: transparent;
}
.nav-link {
    margin-left: 1px;
    font-size: 14px;*/
    /*background: rgba(226,226,226,1);
background: -moz-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(226,226,226,1)), color-stop(50%, rgba(219,219,219,1)), color-stop(51%, rgba(209,209,209,1)), color-stop(100%, rgba(254,254,254,1)));
background: -webkit-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -o-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: -ms-linear-gradient(top, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
background: linear-gradient(to bottom, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(209,209,209,1) 51%, rgba(254,254,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0 );*/
    /*background: linear-gradient(#F1F1F1, #D1D1D1);
    border-bottom: 1px solid transparent;
}
.nav-link.active {
    z-index: 2;
    color: #fff !important;
    cursor: default;
    background-color: #007D40;
    border-color: #007D40;
}*/
/*.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: #fff;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #fff;
}
*/
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #fff !important;
    cursor: default;
    background-color: #007D40 !important;
    /* border: 1px solid green; */
    /* border: 1px solid #ddd; */
    border: 1px solid #007D40;
    /* border: 1px solid #ddd; */
    border-bottom-color: transparent;
}
.dropdown-menu > li > a {
    color: #666666;
}
.dropdown-menu > li.active > a, 
.dropdown-menu > li.active > a:hover, 
.dropdown-menu > li.active > a:focus {
    color: #fff !important;
    cursor: default;
    background-color: #007D40 !important;
    /* border: 1px solid green; */
    /* border: 1px solid #ddd; */
    border: 1px solid #007D40;
    /* border: 1px solid #ddd; */
    border-bottom-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007D40;
}
.btn-secondary-dekra {
    color: #fff;
    background-color: #007D40;
    border-color: #007D36;
}
.btn-secondary-dekra:hover {
    color: #fff;
    background-color: #007D40;
    border-color: #007D36;
}
.btn-secondary-dekra:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #007D40;
    border-color: #007D36;
    box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
}

.mypage-button {
    padding: 10px 10px 12px 10px; 
    line-height: 5px;
    font-size: 14px;
    cursor: pointer;
}

:root {
    --dropdownSize: 30px;
}

.mypage-dropdown {
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-color: #888888;
    box-shadow: 5px 3px 20px #888888;
}

.mypage-dropdown-icon {
    margin-right: 10px;
}

.mypage-dropdown-icon-wrapper {
    width: 40px;
    text-align: right;
    margin-right: 6px;
}

.mypage-dropdown-button {
    text-align: center;
    background-color: #ffffff;
    height: var(--dropdownSize);
    line-height: var(--dropdownSize);
    width: 158px;
    text-decoration: none;
    color: black;
    font-size: 12px;
    display: flex;
}

    .mypage-dropdown-button:hover {
        background-color: #52525E;
        color: #ffffff;
    }